import { Route } from '@angular/router';
import { GroupDetailsComponent } from './presentation/components/group-details/group-details.component';
import { AuthGuard } from './presentation/guards/auth.guard';
import { KeyWithoutKeyRightEditorComponent } from './presentation/components/key-without-key-right-editor/key-without-key-right-editor.component';
import { CustomerPageComponent } from './presentation/pages/customer/customer.page';
import { HomePageComponent } from './presentation/pages/home/home.page';
import { KeysPageComponent } from './presentation/pages/keys/keys.page';
import { LoginSignupPageComponent } from './presentation/pages/login-signup/login-signup.page';
import { NotFoundPageComponent } from "./presentation/pages/not-found/not-found.page";
import { ResetPageComponent } from './presentation/pages/reset/reset.page';
import { RolesPageComponent } from './presentation/pages/roles/roles.page';
import { KeyHolderEditorComponent } from './presentation/components/key-holder-editor/key-holder-editor.component';

export interface PageRoute extends Route {
  name: string;
  shownInMenu: boolean;
  shownInUserDropdown?: boolean;
  shownBeforeLogin: boolean;
  shownAfterLogin: boolean;
  headerName?: string;
  index?: number;
}

declare type Routes = PageRoute[];

export const routes: Routes = [
  {
    name: 'Start',
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    shownInMenu: false,
    shownBeforeLogin: true,
    shownAfterLogin: true,
  },
  {
    headerName: 'Login / Signup',
    name: 'Login-Signup',
    path: 'login',
    component: LoginSignupPageComponent,
    shownInMenu: true,
    shownBeforeLogin: true,
    shownAfterLogin: false,
    index: 0,
  },
  {
    name: 'Home',
    path: 'home',
    component: HomePageComponent,
    shownInMenu: true,
    shownBeforeLogin: false,
    shownAfterLogin: true,
    canActivate: [AuthGuard],
    index: 1,
    children: [
      {
        path: 'customer/:customerId',
        component: CustomerPageComponent,
        children: [
          {
            path: 'keys',
            component: KeyWithoutKeyRightEditorComponent
          },
          {
            path: 'keyholder',
            component: KeyHolderEditorComponent
          }
        ]
      }
    ]
  },
  {
    name: 'home/group/',
    path: 'home/group/:groupid',
        component: HomePageComponent,
        children: [
          {
            path: 'keys',
            component: KeysPageComponent,
          },
          {
        path: 'roles',
            component: RolesPageComponent
          },
          {
            path: 'groupdetails',
            component: GroupDetailsComponent
          },
        ],
    shownInMenu: false,
    shownBeforeLogin: false,
    shownAfterLogin: true,
    canActivate: [AuthGuard]
  },
  {
    name: 'Admin',
    path: 'admin',
    loadChildren: () => import('./presentation/pages/admin/admin.module').then(m => m.AdminPageModule),
    shownInMenu: true,
    shownBeforeLogin: false,
    shownAfterLogin: true,
    canActivate: [AuthGuard],
    index: 2,
  },
  {
    name: 'User Management',
    path: 'usermanagement',
    loadChildren: () => import('./presentation/pages/user-management/user-management.module').then(m => m.UserManagementPageModule),
    shownInMenu: true,
    shownBeforeLogin: false,
    shownAfterLogin: true,
    canActivate: [AuthGuard],
    index: 4,
  },
  {
    name: 'Profile & Settings',
    path: 'profile',
    loadChildren: () => import('./presentation/pages/profile/profile.module').then(m => m.ProfilePageModule),
    shownInMenu: false,
    shownInUserDropdown: true,
    shownBeforeLogin: false,
    shownAfterLogin: true,
    canActivate: [AuthGuard],
    index: 5,
  },
  {
    name: 'Logout',
    path: 'logout',
    loadChildren: () => import('./presentation/pages/logout/logout.module').then(m => m.LogoutPageModule),
    shownInMenu: false,
    shownInUserDropdown: true,
    shownBeforeLogin: false,
    shownAfterLogin: true,
    index: 9,
  },
  {
    name: 'Password Reset',
    path: 'reset',
    component: ResetPageComponent,
    shownInMenu: false,
    shownBeforeLogin: true,
    shownAfterLogin: true,
  },
  {
    name: 'Not Found',
    path: 'notfound',
    loadChildren: () => import('./presentation/pages/not-found/not-found.module').then(m => m.NotFoundPageModule),
    shownInMenu: false,
    shownBeforeLogin: true,
    shownAfterLogin: true,
  },
  {
    name: '404',
    path: '**',
    component: NotFoundPageComponent,
    shownInMenu: false,
    shownBeforeLogin: true,
    shownAfterLogin: true,
  },
];
