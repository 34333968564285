import { Injectable } from '@angular/core';
import { Backend } from '../base/backend';
import { UseCase } from '../base/use-case'
import { RoleModel } from '../models/role.model';
import { Observable } from 'rxjs';
import { Uuid } from '../types/Uuid';
import { UserRoleModel } from '../models/user-role.model';

@Injectable({
    providedIn: 'root'
  })

export class GetAllRolesUsecase implements UseCase<void, Array<RoleModel>> {
  constructor(
    private userRepository: Backend
    ) {
  }

  execute(): Observable<Array<RoleModel>> {
    return this.userRepository.getAllRoles();
  }
}

export class GetAllUserRolesUsecase implements UseCase<void, Array<UserRoleModel>> {
  constructor(
    private userRepository: Backend
    ) {
  }

  execute(): Observable<Array<UserRoleModel>> {
    return this.userRepository.getAllUserRoles();
  }
}

@Injectable({
  providedIn: 'root'
})
export class CreateUserRoleUsecase implements UseCase<UserRoleModel, UserRoleModel> {
  constructor(
    private userRepository: Backend,
    ) { }

  execute(params: UserRoleModel): Observable<UserRoleModel> {
    return this.userRepository.createUserRole(params);
  }
}


@Injectable({
  providedIn: 'root'
})
export class UpdateUserRoleUsecase implements UseCase<UserRoleModel, void> {

  constructor(private userRepository: Backend) { }

  execute(params: UserRoleModel): Observable<void> {
    return this.userRepository.updateUserRole(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeleteUserRoleUsecase implements UseCase<Uuid, void> {

  constructor(private userRepository: Backend) { }

  execute(params: Uuid): Observable<void> {
    return this.userRepository.deleteUserRole(params);
  }
}

