import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { Uuid } from 'src/app/core/types/Uuid';
import { CreateKeyHolderUsecase, DeleteKeyHolderUsecase, GetAllKeyHolderForCustomerIdUsecase, UpdateKeyHolderUsecase } from 'src/app/core/useCases/keyholder.usecases';
import { UiKeyHolderMapper } from '../../mappers/ui-key-holder.mapper';
import { UiKeyHolderModel } from '../../models/ui-key-holder.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../services/notification.service';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete.dialog';


enum SubpageType {
  KeyHolderList,
  KeyHolderEdit
}
@Component({
  selector: 'app-key-holder-editor',
  templateUrl: './key-holder-editor.component.html',
  styleUrls: ['./key-holder-editor.component.scss']
})
export class KeyHolderEditorComponent implements OnInit {
  SubpageType = SubpageType;
  currentSubpage: SubpageType = SubpageType.KeyHolderList;

  currentKeyHolder: UiKeyHolderModel = {email: ""};
  currentCustomerId!: Uuid;
  editExistingKeyHolder = false;

  allKeyHolder: UiKeyHolderModel[] = [];
  uiKeyHolderMapper = new UiKeyHolderMapper();

  formGroupKeyHolder: FormGroup;

  keyHolderTableColumns = ['firstName', 'lastName', 'email', 'position', 'badgeId'];
  keyHolderTableColumnsAll = ['select', ...this.keyHolderTableColumns];
  khDataSource: MatTableDataSource<UiKeyHolderModel> = 
    new MatTableDataSource();

  routeSubscription!: Subscription;

  @ViewChild(MatSort, { static: true }) sort?: MatSort;
  

  constructor(
    private getKeyHoldersForCustomerId: GetAllKeyHolderForCustomerIdUsecase,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private createKeyHolderUsecase: CreateKeyHolderUsecase,
    private updateKeyHolderUsecase: UpdateKeyHolderUsecase,
    private deleteKeyHolderUsercase: DeleteKeyHolderUsecase,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) 
  { 
    this.formGroupKeyHolder = this.formBuilder.group({
      firstname: [
        {value: '', disabled: false},
        [Validators.maxLength(200), Validators.required]
      ],
      lastname: [
        {value: '', disabled: false},
        [Validators.maxLength(200), Validators.required]
      ],
      email: [
        {value: '', disabled: false},
        [Validators.maxLength(200), Validators.required, Validators.email]
      ],
      position: [
        {value: '', disabled: false},
        [Validators.maxLength(200), Validators.required]
      ],
      badgeId: [
        {value: '', disabled: false},
        [Validators.maxLength(200), Validators.required]
      ],
    });
    this.routeSubscription = this.route.params.subscribe(p => {
      this.currentCustomerId = p["customerId"];
      this.loadAllKeyholder(this.currentCustomerId);
    }
      
    );
  }

  ngOnInit(): void {
  }

  loadAllKeyholder(customerId: Uuid): void {
    this.getKeyHoldersForCustomerId.execute(customerId).subscribe( 
      kh => {
        this.allKeyHolder = 
          kh.map(this.uiKeyHolderMapper.mapTo.bind(this));
        this.khDataSource.data = this.allKeyHolder;
      }
    )
    this.insertCurrentKeyHolderInForm();
  }

  insertCurrentKeyHolderInForm(){
    this.formGroupKeyHolder.patchValue({
      firstname: this.currentKeyHolder?.firstName,
      lastname: this.currentKeyHolder?.lastName,
      email: this.currentKeyHolder?.email,
      position: this.currentKeyHolder?.badgeId,
      badgeId: this.currentKeyHolder?.badgeId,
    })
  }

  addKeyHolder(){
    this.editExistingKeyHolder = false;
    this.currentKeyHolder = {
      email: "",
      customer: this.currentCustomerId
    };
    this.insertCurrentKeyHolderInForm();
    this.currentSubpage = this.SubpageType.KeyHolderEdit
  }

  editKeyHolder(selectedKeyHolder: UiKeyHolderModel) {
    this.editExistingKeyHolder = true;
    this.currentKeyHolder = selectedKeyHolder;
    this.insertCurrentKeyHolderInForm();
    this.currentSubpage = this.SubpageType.KeyHolderEdit;
  }

  confirmKeyHolderDelete(keyHolder: UiKeyHolderModel[]) {
    //TODO: list key Holder to delete 
    const ref = this.dialog.open(ConfirmDeleteDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: "Key Holder"
    });
    ref.afterClosed()
      .pipe(
        filter(result => result),
      )
      .subscribe(this.deleteKeyHolder.bind(this, keyHolder))
  }

  deleteKeyHolder(keyHolder: UiKeyHolderModel[]){
    let deleteOps = [];
    for (const kh of keyHolder) {
      deleteOps.push(
        this.deleteKeyHolderUsercase.execute(kh.id!)
      );
    }
    const deleteSelected = forkJoin(deleteOps);
    deleteSelected.subscribe(() => {
      for (const kh of keyHolder) {
        this.khDataSource.data.splice(this.khDataSource.data.indexOf(kh), 1);
        this.khDataSource._updateChangeSubscription();
      }
    }, err => this.notificationService.notifyError(
                "Key holder deletion failed: " + err.message)
    );
  }

  submitCurrentKeyHolder(){
  
    this.currentKeyHolder.lastName = this.formGroupKeyHolder.get("lastname")!.value;
    this.currentKeyHolder.firstName = this.formGroupKeyHolder.get("firstname")!.value;
    this.currentKeyHolder.email = this.formGroupKeyHolder.get("email")!.value;
    this.currentKeyHolder.position = this.formGroupKeyHolder.get("position")!.value;
    this.currentKeyHolder.badgeId = this.formGroupKeyHolder.get("badgeId")!.value;

    let kh = this.uiKeyHolderMapper.mapFrom(this.currentKeyHolder);
    if (this.editExistingKeyHolder == true){
      this.updateKeyHolderUsecase.execute(kh).subscribe(newKeyHolder => {
        this.loadAllKeyholder(this.currentCustomerId);
      }, err => this.notificationService.notifyError(
                  "Creating Key Holder failed." + err.message
                )
      );
    }
    else{
      this.createKeyHolderUsecase.execute(kh).subscribe(newKeyHolder => {
        this.loadAllKeyholder(this.currentCustomerId);
      }, err => this.notificationService.notifyError(
                  "Creating Key Holder failed." + err.message
                )
      );
    }

    this.currentSubpage = this.SubpageType.KeyHolderList;
  
  }

}
