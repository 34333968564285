import { Mapper } from "src/app/core/base/mapper";
import { KeyRightModel } from "src/app/core/models/key-right.model";
import { UiKeyRightModel } from "../models/ui-key-right.model";
import { UiTimeWindowMapper } from "./ui-time-window.mapper";

export class UiKeyRightMapper extends Mapper<UiKeyRightModel, KeyRightModel>{
    twMapper = new UiTimeWindowMapper;

    mapFrom(param: UiKeyRightModel): KeyRightModel {
        let kr = {... param as any};
        if (param.startTs && param.startTs instanceof Date){
            kr.startTs = param.startTs.toISOString();
        }
        if (param.expirationTs && param.expirationTs instanceof Date){
            kr.expirationTs = param.expirationTs.toISOString();
        }
        kr.timeWindows = [];
        for (let tw of param.timeWindows){
            kr.timeWindows.push(this.twMapper.mapFrom(tw))
        }
        return kr as KeyRightModel;
    }
    mapTo(param: KeyRightModel): UiKeyRightModel {
        let kr = {... param as any};
        if (param.startTs){
            kr.startTs = new Date(param.startTs);
        }
        if (param.expirationTs){
            kr.expirationTs = new Date(param.expirationTs);
        }
        kr.timeWindows = [];
        for (let tw of param.timeWindows){
            kr.timeWindows.push(this.twMapper.mapTo(tw))
        }
        return kr as UiKeyRightModel;
    }    
}
