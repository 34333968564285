import { Injectable } from '@angular/core';
import { Backend } from '../base/backend';
import { UseCase } from '../base/use-case'
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Credentials } from '../models/credentials.model';
import { PasswordChangeModel } from '../models/passwort-change.model';
import { UserService } from "../services/user.service";

@Injectable({
    providedIn: 'root'
})
export class LoginUserUsecase implements UseCase<Credentials, boolean> {

    constructor(private backend: Backend, private userService: UserService) { }

    execute(params: Credentials): Observable<boolean> {
        return this.backend
            .loginUser(params)
            .pipe(
                tap(succ => succ && this.userService.onUserLogin())
            )
    }
}

@Injectable({
    providedIn: 'root'
})
export class LogoutUserUsecase implements UseCase<void, boolean> {

    constructor(private userRepository: Backend, private userService: UserService) { }

    execute(): Observable<boolean> {
        return this.userRepository
            .logoutUser()
            .pipe(
                tap(succ => succ && this.userService.onUserLogout())
            )
    }
}

@Injectable({
    providedIn: 'root'
})
export class IsAuthenticatedUsecase implements UseCase<void, boolean> {

    constructor(private userRepository: Backend, private userService: UserService) { }

    execute(): Observable<boolean> {
        return this.userRepository.isAuthenticated()
        .pipe(
            tap(isAuthenticated => this.userService.onAuthStateChange(isAuthenticated))
        )
    }
}

@Injectable({
  providedIn: 'root'
})
export class RequestNewPasswordUseCase implements UseCase<string, void> {
  constructor(private userRepository: Backend) { }

  execute(params: string): Observable<void> {
    return this.userRepository.requestNewPassword(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordUseCase implements UseCase<PasswordChangeModel, void>{
  constructor(private userRepository: Backend) { }

  execute(params: PasswordChangeModel): Observable<void> {
    return this.userRepository.resetPassword(params);
  }
}
