// TODO: multi-selecting users and assigning them together to a group with a role

import { GetAllUsersUsecase } from 'src/app/core/useCases/user.usecases';
import { Component, OnInit, ViewChild } from "@angular/core";
import { GroupModel } from "src/app/core/models/group.model";
import { UiUserModel} from '../../models/ui-user.model';
import { MatDialog } from '@angular/material/dialog';
import { UiUserMapper } from '../../mappers/ui-user.mapper';
import { DomSanitizer } from '@angular/platform-browser';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: "app-users",
  templateUrl: "./user-management.page.html",
  styleUrls: ["./user-management.page.scss"],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class UserManagementPageComponent implements OnInit {
  selectedGroup?: GroupModel;
  usersArray = new Array<UiUserModel>();
  public selectedUser?: UiUserModel;
  public isExpanded: boolean = false;
  public panelOpenState: boolean = false;
  private uiUserMapper = new UiUserMapper(this.sanitizer);
  dataSource: MatTableDataSource<UiUserModel> = new MatTableDataSource();

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  constructor(
    public dialog: MatDialog,
    private getAllUsers: GetAllUsersUsecase,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.getAllUsers.execute().subscribe( array => {
      this.dataSource.data = array.map(this.uiUserMapper.mapTo.bind(this.uiUserMapper));
    });
  }

  isRowEditable() {
    return true;
  }
}
