<app-data-table mat-table [dataSource]="this.dataSource!" matSort
  [editableFields]="false" [editCond]="this.isRowEditable.bind(this)" [columns]="['name', 'email']" class="user-table">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
    <td mat-cell *matCellDef="let user"> {{user.firstName + " " +
      user.lastName}} </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
    <td mat-cell *matCellDef="let user"> {{user.email}} </td>
  </ng-container>
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let user" [attr.colspan]="3">
      <div class="user-detail"
        [@detailExpand]="user === this.selectedUser ? 'expanded' : 'collapsed'">
        <div class="detail-container">
          <mat-card appearance="outlined" class="user-card">
            <img mat-card-md-image [src]="user.image.image">
          </mat-card>
          <div>
            <mat-list-item>
              <mat-label position="floating">Profession:</mat-label>
              <mat-label position="floating"> {{this.user.profession}}
              </mat-label>
            </mat-list-item>
            <mat-list-item>
              <mat-label position="floating">Company:</mat-label>
              <mat-label position="floating"> {{this.user.company}} </mat-label>
            </mat-list-item>
            <mat-list-item>
              <mat-label position="floating">Comment:</mat-label>
              <mat-label position="floating"> {{this.user.comment}} </mat-label>
            </mat-list-item>
          </div>
        </div>
      </div>
    </td>
  </ng-container>
  <tr mat-row *matRowDef="let row; columns: ['name', 'email'];" class="user-row"
    [class.expanded-row]="this.selectedUser === row"
    (click)="this.selectedUser = this.selectedUser === row ? null : row">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"
    class="detail-row"></tr>
</app-data-table>
