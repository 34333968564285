<h1 mat-dialog-title>{{"Send Key Mail" | translate}}</h1>
<form (ngSubmit)="shareKeyMail()" [formGroup]="this.newKeyMailForm">
    <div mat-dialog-content>
        <mat-form-field position="floating" appearance="fill">
            <mat-label>
                {{"Enter email of Key Mail recipient" | translate}}
            </mat-label>
            <input matInput formControlName="email">
        </mat-form-field>
        <!-- <button mat-icon-button>
            <mat-icon>add_circle</mat-icon>
        </button> -->
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button [mat-dialog-close]="false">
            {{"Cancel" | translate }}
        </button>
        <button mat-raised-button color="primary" type="submit"
            [mat-dialog-close]="true" [disabled]="this.newKeyMailForm.invalid">
            {{"Send Key Mail" | translate }}
        </button>
    </div>
</form>
