<h1 mat-dialog-title>{{"Please Confirm" | translate}}</h1>
<div mat-dialog-content>{{"Delete: " + target + "?" | translate}} </div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">
        {{"Cancel" | translate }}
    </button>
    <button mat-button color="warn" [mat-dialog-close]="true">
        {{"Delete" | translate }}
    </button>
</div>
