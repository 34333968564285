import { Mapper } from 'src/app/core/base/mapper';
import { RoleModel } from 'src/app/core/models/role.model';
import { WebRoleModel } from '../models/web-role.model';


export class WebRoleMapper extends Mapper<WebRoleModel, RoleModel>{
    mapFrom(param: WebRoleModel): RoleModel{
        return param as RoleModel
    }

    mapTo(param: RoleModel): WebRoleModel{
       return param as WebRoleModel
    }
}
