import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmDeleteDialogComponent } from './confirm-delete.dialog';
import { AddRoleDialogComponent } from './add-role.dialog';
import { ErrorDialogComponent } from "./error.dialog";
import { AlertDialogComponent } from './alert.dialog';
import { CreateGroupDialogComponent } from './create-group.dialog';
import { ConfirmDialogComponent } from './confirm.dialog';
import { KeyMailDialogComponent } from './key-mail.dialog';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    SharedModule.forRoot(),
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
  ],
  declarations: [
    AddRoleDialogComponent,
    ConfirmDeleteDialogComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    ErrorDialogComponent,
    CreateGroupDialogComponent,
    KeyMailDialogComponent,
  ],
  exports: [
    AddRoleDialogComponent,
    ConfirmDeleteDialogComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    ErrorDialogComponent,
    CreateGroupDialogComponent,
    KeyMailDialogComponent,
  ]
})
export class DialogsModule {}
