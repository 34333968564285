import { Mapper } from 'src/app/core/base/mapper';
import { SignupModel } from 'src/app/core/models/signup.model';
import { WebSignupModel } from '../models/web-signup.model';


export class WebSignupMapper extends Mapper<WebSignupModel, SignupModel>{
    mapFrom(param: WebSignupModel): SignupModel{
        return param as SignupModel
    }

    mapTo(param: SignupModel): WebSignupModel{
       return param as WebSignupModel
    }
}
