import { Mapper } from 'src/app/core/base/mapper';
import { SignupModel } from 'src/app/core/models/signup.model';
import { UiSignupModel } from '../models/ui-signup.model';


export class UiSignupMapper extends Mapper<UiSignupModel, SignupModel>{
    mapFrom(param: UiSignupModel): SignupModel{
        return param as SignupModel
    }

    mapTo(param: SignupModel): UiSignupModel{
       return param as UiSignupModel
    }
}
