<div class="layout-container">
    <div class="layout mat-typography">
        <h2>Reset Password</h2>
        <ng-container *ngIf="!keyParam; else: pwEntryForm">
            <app-password-request-reset></app-password-request-reset>
        </ng-container>
        <ng-template #pwEntryForm>
            <app-password-reset [keyParam]="keyParam"></app-password-reset>
        </ng-template>
    </div>
</div>
