<form class="login-mask" [formGroup]="loginForm" (ngSubmit)="submitForm()">

  <mat-form-field position="floating" appearance="outline">
    <mat-label for="email" position="floating">
      {{ 'Email' | translate }}
    </mat-label>
    <input matInput id="email" type="email" class="validate"
      formControlName="email">
  </mat-form-field>

  <br>

  <mat-form-field position="floating" appearance="outline">
    <mat-label for="pwd" position="floating">
      {{ 'Password' | translate }}
    </mat-label>
    <input matInput id="pwd" class="validate"
      [type]="hidePW ? 'password' : 'text'" 
      formControlName="password"
    >
    <mat-icon matSuffix (click)="hidePW = !hidePW">
      {{hidePW ? 'visibility_off' : 'visibility'}}
    </mat-icon>
  </mat-form-field>

  <br>
  
  <button color="accent" mat-flat-button type="submit"
    [disabled]="!loginForm.valid">
    {{ 'Login' | translate }}
  </button>

  <p>
    <a href="/reset" class="link">
      {{ 'Forgot Password?' | translate }}
    </a>
  </p>
  <p>
    <a href="mailto:info@comydo.com" class="link">
      {{ 'Trouble logging in?' | translate }}
    </a>
  </p>
</form>
