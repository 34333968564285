<h1 mat-dialog-title >{{data.title}}</h1>
<div class="container">
    <div class="message">
        {{data.message}}
    </div>
</div>
<div mat-dialog-actions >
    <button mat-button [mat-dialog-close]="false">
        {{"Close" | translate}}
    </button>
</div>
