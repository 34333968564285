<div class="layout" [ngSwitch]="this.currentSubpage">
    <ng-container *ngSwitchCase="SubpageType.KeyHolderList">
        <app-data-table matSort
            [dataSource]="this.khDataSource"
            [editableFields]="true"
            [addButtonEnabled]="true"
            [editButtonEnabled]="true"
            [columns]="this.keyHolderTableColumns"
            (addRow)="addKeyHolder()"
            (editRow)="editKeyHolder($event)"
            (deleteRows)="confirmKeyHolderDelete($event)"
        >
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{"First Name" | translate}}
                </th>
                <td mat-cell *matCellDef="let keyHolder">
                    {{keyHolder.firstName}}
                </td>
            </ng-container>

            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{"Last Name" | translate}}
                </th>
                <td mat-cell *matCellDef="let keyHolder">
                    {{keyHolder.lastName}}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{"Email" | translate}}
                </th>
                <td mat-cell *matCellDef="let keyHolder">
                    {{keyHolder.email}}
                </td>
            </ng-container>

            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{"Position" | translate}}
                </th>
                <td mat-cell *matCellDef="let keyHolder">
                    {{keyHolder.position}}
                </td>
            </ng-container>

            <ng-container matColumnDef="badgeId">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{"Badge ID" | translate}}
                </th>
                <td mat-cell *matCellDef="let keyHolder">
                    {{keyHolder.badgeId}}
                </td>
            </ng-container>

            <tr mat-row 
                *matRowDef="let row; columns: this.keyHolderTableColumnsAll;"
            ></tr>

        </app-data-table>
    </ng-container>
    <ng-container *ngSwitchCase="SubpageType.KeyHolderEdit">
        <div>
            <button mat-icon-button (click)="currentSubpage=SubpageType.KeyHolderList">
                <mat-icon> arrow_back </mat-icon>
            </button>
            <span class="title">{{"Add/Edit Key Holder" | translate}}</span>

            <form [formGroup]="formGroupKeyHolder" (ngSubmit)="submitCurrentKeyHolder()">
                <mat-form-field appearance="outline">
                    <mat-label for="firstname">
                        {{"First Name" | translate}}
                    </mat-label>
                    <input matInput type="text" id="firstname" formControlName="firstname">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label for="lastname">
                        {{"Last Name" | translate}}
                    </mat-label>
                    <input matInput type="text" id="lastname" formControlName="lastname">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label for="email">
                        {{"E-Mail" | translate}}
                    </mat-label>
                    <input matInput type="text" id="email" formControlName="email">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label for="position">
                        {{"Position" | translate}}
                    </mat-label>
                    <input matInput type="text" id="position" formControlName="position">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label for="badgeId">
                        {{"Badge ID" | translate}}
                    </mat-label>
                    <input matInput type="text" id="badgeId" formControlName="badgeId">
                </mat-form-field>

                <button mat-flat-button 
                    color="primary" 
                    [disabled]="!this.formGroupKeyHolder.valid"
                    type="submit"
                >
                    {{"Save Keyholder" | translate}}
                </button>
            </form>
        </div>
    </ng-container>
</div>
