import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Uuid } from 'src/app/core/types/Uuid';

enum CustomerTabType {
  keyNoKrEditor,
  keyHolderEditor
}

@Component({
  selector: 'app-customer',
  templateUrl: './customer.page.html',
  styleUrls: ['./customer.page.scss']
})
export class CustomerPageComponent implements OnInit, OnDestroy {
  CustomerTabType = CustomerTabType;

  currentCustomerTab: CustomerTabType | undefined = undefined;
  routerParams!: Subscription;
  routerEvents!: Subscription;

  currentUrl!: string;

  constructor(
    private router: Router,
  )
  {
    this.routerEvents = this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.updateTabsHeader.bind(this)(e.url);
      }
    })
    this.updateTabsHeader(this.router.url)
  }

  updateTabsHeader(url: String) {

    if (url.endsWith('keys')){
      this.currentCustomerTab = CustomerTabType.keyNoKrEditor;
    }
    else if (url.endsWith('keyholder')) {
      this.currentCustomerTab = CustomerTabType.keyHolderEditor;
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.routerEvents.unsubscribe();
  }



}
