import { AfterViewInit, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
import { appTitle as globalAppTitle } from '../global';
import { UserService } from './core/services/user.service';
import { ConfirmDialogComponent } from './presentation/dialogs/confirm.dialog';
import { PageRoute, routes } from './routes';
import { HealthCheckService } from './services/healthcheck.service';
import { LanguageService } from './services/language.service';
import { PageLocationService } from './services/page-location.service';
import { ThemeService } from './services/theme.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
    appTitle = globalAppTitle;
    titleEl: HTMLTitleElement;
    pages: PageRoute[] = [];
    rootPage: PageRoute = routes[0];
    currentPage: PageRoute = this.rootPage;
    selectedTheme: string = this.themeService.theme$.value;
    isNative?: boolean;


    constructor(
        private langService: LanguageService,
        private pageLocationService: PageLocationService,
        private router: Router,
        private themeService: ThemeService,
        private translateService: TranslateService,
        private healthCheck: HealthCheckService,
        private dialog: MatDialog,
        public userService: UserService
    ) {
        this.initializeApp();
        this.titleEl = document.getElementsByTagName('title')[0];
        this.pages = this.pageLocationService.getAllPages(false);
        this.healthCheck.checkBackendOnline();

    }

    updateCurrentPage(event: NavigationEnd | NavigationCancel | NavigationError) {
        let updatedUrl = event.url.split('?')[0];
        if (event instanceof NavigationEnd && event.urlAfterRedirects) {
            updatedUrl = event.urlAfterRedirects.split('?')[0];
        }
        updatedUrl = updatedUrl[0] === '/' ? updatedUrl.slice(1) : updatedUrl;
        this.currentPage = this.pages.find(page => page.path && updatedUrl.startsWith(page.path)) || this.rootPage;
        this.titleEl.innerText = `${this.appTitle} - ${this.currentPage?.name}`;
    }

    ngAfterViewInit() {
        type NavigationEvent = NavigationEnd | NavigationCancel | NavigationError;

        this.router.events
            .pipe(filter(event =>
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError), map(e => e as NavigationEvent)
            ).subscribe(
                (event: NavigationEvent) => { this.updateCurrentPage(event) }
            )
    }

    initializeApp() {
        this.setLanguage();

    }

    private setLanguage() {
        this.langService.getLanguage().subscribe(language => {
            if (language) {
                this.translateService.use(language.code);
            }
        })
    }

    logout() {
        const dRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                header: this.translateService.instant('logout'),
                message: this.translateService.instant('logoutConfirmMessage'),
                no_action_button: this.translateService.instant('cancel'),
                confirm_action_button: this.translateService.instant('ok'),
            }
        });
        dRef.afterClosed()
            .pipe(filter(r => r))
            .subscribe(res => {
                this.router.navigateByUrl('/logout')
            });
    }

    onLoggedIn() {
    }

    onLoggedOut() {
        this.router.navigateByUrl('/login');
    }

    openPage(p: PageRoute) {
        this.router.navigateByUrl(p.path!);
    }
}
