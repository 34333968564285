<div class='layout'>
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        <a mat-tab-link
            [active]="this.currentCustomerTab === CustomerTabType.keyNoKrEditor"
            routerLink="keys"
        >
            {{"KeysWithoutKeyRights" | translate}}
        </a>
        <a mat-tab-link 
            [active]="this.currentCustomerTab === CustomerTabType.keyHolderEditor"
            routerLink="keyholder"
        >
            {{"ManageKeyHolders" | translate }}
        </a>
    </nav>
    
    <mat-tab-nav-panel #tabPanel>
        <ng-container [ngSwitch]="this.currentCustomerTab">
            <ng-container *ngSwitchCase="this.CustomerTabType.keyNoKrEditor">
                <router-outlet></router-outlet>
            </ng-container>

            <ng-container *ngSwitchCase="this.CustomerTabType.keyHolderEditor">
                <router-outlet></router-outlet>
            </ng-container>

            <ng-container *ngSwitchDefault>
                {{"An unexpected error occured." | translate}}
            </ng-container>
        </ng-container>
    </mat-tab-nav-panel>
</div>

