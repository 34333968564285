import { Mapper } from "src/app/core/base/mapper";
import { DeviceInfoModel } from "src/app/core/models/device.models";
import { WebDeviceInfoModel } from "../models/web-device.models";

export class WebDeviceInfoMapper extends Mapper<WebDeviceInfoModel, DeviceInfoModel>{
    mapFrom(param: WebDeviceInfoModel): DeviceInfoModel {
        let deviceInfo: DeviceInfoModel = {
            deviceRefId: param.deviceRefId,
            name: param.name,
            deviceMqttId: param.deviceMqttId,
            imei: param.imei,
            simNr: param.simNr,
            simProvider: param.simProvider,
            serialNr: param.serialNr,
            addressString: param.address,
            customer: param.customer,
            stats: param.stats,
            isOnline: param.isOnline
        }
        return deviceInfo
    }

    mapTo(param: DeviceInfoModel): WebDeviceInfoModel {
        let webDeviceInfo: WebDeviceInfoModel = {
            deviceRefId: param.deviceRefId,
            name: param.name,
            deviceMqttId: param.deviceMqttId,
            imei: param.imei,
            simNr: param.simNr,
            simProvider: param.simProvider,
            serialNr: param.serialNr,
            address: param.addressString,
            customer: param.customer,
            stats: param.stats,
            isOnline: param.isOnline
        }
        return webDeviceInfo;
    }
}
