import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { theme } from '../../global';
import { LocalStorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public theme$ = new BehaviorSubject(theme.availableThemes[0]);
  availableThemes = theme.availableThemes;

  constructor( private storage: LocalStorageService ) 
  {
    this.fetchTheme();
  }

  private async fetchTheme(){
    this.storage.get('appTheme').then((storedTheme: string | null) => {     //NOTE(keep those nulls for integration reasons)
      if (storedTheme){
        if (this.themeExists(storedTheme)) {
          this.theme$.next(storedTheme);
        }
      }
    });
  }

  themeExists(val: string): boolean {
    return theme.availableThemes.indexOf(val) !== -1;
  }

  setActiveTheme(newTheme: string) {
    this.theme$.next(newTheme);
    this.storage.set('appTheme', newTheme);
  }

  getActiveTheme() {
    return this.theme$.asObservable();
  }

  getAvailableThemes() {
    return this.availableThemes;
  }
}
