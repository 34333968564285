export enum ComydoKeyType {
    Custom = "custom",
    Plate = "plate",
    Badge = "badge",
    PIN = "pin",
    Tracking = "tracking",
}

export const ComydoKeyNames = new Map<string, string>([
    [ComydoKeyType.Custom, "QR Code"],
    [ComydoKeyType.Plate, "License Plate"],
    [ComydoKeyType.Badge, "Badge"],
    [ComydoKeyType.PIN, "PIN"],
    [ComydoKeyType.Tracking, "Shipment Tracking Key"]
]);
