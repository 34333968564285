import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestNewPasswordUseCase } from 'src/app/core/useCases/credentials.usecases';

@Component({
  selector: 'app-password-request-reset',
  templateUrl: './pw-request-reset.component.html',
  styleUrls: ['./pw-request-reset.component.scss']
})
export class PasswordRequestResetComponent {
  requestNewPwForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private requestNewPw: RequestNewPasswordUseCase,
  ) {
      this.requestNewPwForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
    })
  }

  submitForm() {
    this.requestNewPw.execute(this.requestNewPwForm.value['email']).subscribe();
  }

}
