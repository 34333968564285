import { Mapper } from 'src/app/core/base/mapper';
import { UserModel } from 'src/app/core/models/user.model';
import { WebUserModel } from '../models/web-user.model';
import { WebImageB64sMapper } from './web-image-b64s.mapper';


export class WebUserMapper extends Mapper<WebUserModel, UserModel>{
    mapFrom(param: WebUserModel): UserModel{
        let webImageB64sMapper = new WebImageB64sMapper();
        let user: UserModel = {
            id: param.id,
            email: param.email,
            firstName: param?.firstName,
            lastName: param?.lastName,
            profession: param?.profession,
            comment: param?.comment,
            company: param?.company,
            roles: param?.roles,
        };
        if (param.imageB64){
            user.image = webImageB64sMapper.mapFrom(param.imageB64);
        };
        if (param.permissions){
            user.permissions = param.permissions;
        };
        return user;
    }

    mapTo(param: UserModel): WebUserModel{
        let webImageB64sMapper = new WebImageB64sMapper();
        let user: WebUserModel = {
            id: param.id,
            email: param.email,
            firstName: param?.firstName,
            lastName: param?.lastName,
            profession: param?.profession,
            comment: param?.comment,
            company: param?.company,
            password: param?.password,
            roles: param?.roles,
        }
        if (param.image){
            user.imageB64 = webImageB64sMapper.mapTo(param.image);
        }
        if (param.permissions){
            user.permissions = param.permissions;
        }
        return user;
    }
}
