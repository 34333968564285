import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UserModel } from "src/app/core/models/user.model";
import { UiUserMapper } from "../mappers/ui-user.mapper";
import { UiUserModel } from "../models/ui-user.model";

@Pipe({ name: 'userToUIUser' })
export class UserToUIUserPipe implements PipeTransform {
    uiUserMapper = new UiUserMapper(this.sanitizer); 

    constructor(private sanitizer: DomSanitizer){};

    transform(user: UserModel, ...args: any[]): UiUserModel {
        return this.uiUserMapper.mapTo(user)
    }
}
