import { Pipe, PipeTransform } from '@angular/core';
import { Uuid } from 'src/app/core/types/Uuid';
import { UiKeyHolderModel } from '../models/ui-key-holder.model';

@Pipe({ name: 'resolveKeyHolderInfoSring' })
export class ResolveKeyHolderInfoStringPipe implements PipeTransform {
    transform(keyHolderId: Uuid, keyHolderMap: Map<Uuid, UiKeyHolderModel>): string {
        let info = 
            `${keyHolderMap.get(keyHolderId)?.firstName} ` +
            `${keyHolderMap.get(keyHolderId)?.lastName} ` +
            `(${keyHolderMap.get(keyHolderId)?.email})`
        if (info === 'undefined undefined (undefined)'){
            info = 'None';
        }
        return info ?? "" as string
    }
}
