import { Component, Input } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ResetPasswordUseCase } from "src/app/core/useCases/credentials.usecases";

const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => { 
    const password = control.get('password');
    const passwordRepeat = control.get('passwordRepeat');

    return password?.value !== passwordRepeat?.value ? { passwordsDontMatch: true } : null;
};

@Component({
    selector: 'app-password-reset',
    templateUrl: './pw-reset.component.html',
    styleUrls: ['./pw-reset.component.scss']
})
export class PasswordResetComponent {
    @Input() keyParam!: string;
    resetPwFg: FormGroup;

    constructor(
        private resetPwUc: ResetPasswordUseCase,
        private _snackBar: MatSnackBar,
        private router: Router,
    ) {

        this.resetPwFg = new FormGroup({
            'password': new FormControl('', Validators.required),
            'passwordRepeat': new FormControl('', Validators.required)
        }, { validators: passwordMatchValidator });
        this.resetPwFg.get
    };

    changePW() {
        this.resetPwUc.execute({
            password: this.resetPwFg.get('password')?.value,
            passwordRepeat: this.resetPwFg.get('passwordRepeat')?.value,
            key: this.keyParam,
        }
        ).subscribe({
            next: () => {
                this.present_snack_msg("Password changed succesfully");
                this.router.navigateByUrl('logout');
            },
            error: err => { this.present_snack_msg(err.error.password) },
        });
    }

    async present_snack_msg(msg: string) {
        this._snackBar.open(msg, "OK", {
            duration: 5000,
            verticalPosition: 'top',
        });
    }

}
