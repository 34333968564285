import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Backend } from 'src/app/core/base/backend';
import { Credentials } from 'src/app/core/models/credentials.model';
import { CustomerModel } from 'src/app/core/models/customer.model';
import { DeviceInfoModel } from 'src/app/core/models/device.models';
import { GroupTreeModel } from 'src/app/core/models/group-tree.models';
import { GroupModel } from 'src/app/core/models/group.model';
import { ImagePngModel } from 'src/app/core/models/image-png.model';
import { InvitationModel } from 'src/app/core/models/invitation.model';
import { KeyHolderModel } from 'src/app/core/models/key-holder.model';
import { KeyShareMailModel } from 'src/app/core/models/key-share-model';
import { KeyModel } from 'src/app/core/models/key.model';
import { KeysOfGroupModel } from 'src/app/core/models/keys-of-group.model';
import { PasswordChangeModel } from 'src/app/core/models/passwort-change.model';
import { RoleModel } from 'src/app/core/models/role.model';
import { SignupModel } from 'src/app/core/models/signup.model';
import { UserModel } from 'src/app/core/models/user.model';
import { Uuid } from 'src/app/core/types/Uuid';
import { NotificationService } from 'src/app/presentation/services/notification.service';
import { authCodeFlowConfig, environment } from 'src/environments/environment';
import { WebDeviceInfoMapper } from './mapper/web-device.mapper';
import { WebTreeMapper } from './mapper/web-group-tree.mapper';
import { WebGroupMapper } from './mapper/web-group.mapper';
import { WebImagePngMapper } from './mapper/web-image-png.mapper';
import { WebInvitationMapper } from './mapper/web-invitation-mapper';
import { WebKeyHolderMapper } from './mapper/web-key-holder.mapper';
import { WebKeyMapper } from './mapper/web-key.mapper';
import { WebKeysOfGroupMapper } from "./mapper/web-keys-of-group.mapper";
import { WebRoleMapper } from './mapper/web-role.mapper';
import { WebSignupMapper } from './mapper/web-signup.mapper';
import { WebUserMapper } from './mapper/web-user.mapper';
import { WebGroupTreeModel } from './models/web-group-tree.model';
import { WebGroupModel } from './models/web-group.model';
import { WebInvitationModel } from './models/web-invitation.model';
import { WebKeyHolder } from './models/web-key-holder.model';
import { WebKeyModel, WebKeysOfGroupModel } from './models/web-key.model';
import { WebRoleModel } from './models/web-role.model';
import { WebUserModel } from './models/web-user.model';
import { UserRoleModel } from 'src/app/core/models/user-role.model';
import { WebUserRoleModel } from './models/web-user-role.model';
import { WebUserRoleMapper } from './mapper/web-user-role.mapper';


type ErrorHandler<T> = (err: HttpErrorResponse, caught: Observable<T>) => Observable<T>;

export class EndPoint {
  public backend: any;

  constructor(
    public path: string,
  ) { }

  toURL(...params: Array<[string, any]>) {
    let url = environment.apiUrl + this.path;
    for (let param of params) {
      url = url.replace(`%${param[0]}%`, param[1]);
    }
    return url;
  }
}
@Injectable({
  providedIn: 'root'
})
export class WebBackend extends Backend {
  static URL_paths: string[] = [
    'get-user-info',
    'api-token-oauth',
    'auth/convert-token',
    'user/reset',
    // Users
    'user',
    'user/%id%',
    'user/me',
    'user/me/delete',
    'user/reset/request',
    'user/signup',
    //Groups
    'group',
    'group/%id%',
    'group/tree',
    'group/%id%/keys',
    'group/%id%/users',
    'group/%id%/keys/expiration?keyid=%keyid%',
    'group/%id%/open',
    'group/%id%/customer',
    //Devices
    'device/info',
    'devices/%id%/info',
    //Keys
    'key',
    'key/%id%',
    'key/%id%/share/mail',
    'keyholder',
    'keyholder/%id%',
    'keyright/%id%',
    'key/%id%/qr.png',
    //customer scoped
    'customer/%id%/unassigned-keys',
    'customer/%id%/keyholder',
    //Roles
    'roles',
    'userrole',
    'userrole/%id%',
    //Invitation
    'invitation',
    'invitation/%id%/send',
  ];
  endPoints: { [path: string]: EndPoint } = {};

  webUserMapper = new WebUserMapper();
  webGroupMapper = new WebGroupMapper();
  webDeviceMapper = new WebDeviceInfoMapper();
  webTreeMapper = new WebTreeMapper();
  webKeysOfGroupMapper = new WebKeysOfGroupMapper();
  webKeyHolderMapper = new WebKeyHolderMapper();
  webKeyMapper = new WebKeyMapper();
  webInvitationMapper = new WebInvitationMapper();
  webRoleMapper = new WebRoleMapper();
  webUserRoleMapper = new WebUserRoleMapper();
  webSignupMapper = new WebSignupMapper();

  constructor(
    public http: HttpClient,
    private oauthService: OAuthService,
    private notificationService: NotificationService,
    //private logoutUserUC: LogoutUserUsecase,
  ) {
    super();

    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setupAutomaticSilentRefresh();

    for (const path of WebBackend.URL_paths) {
      this.endPoints[path] = new EndPoint(path);
    }

  }
  
  // status: complete
  getAllUsers(handleError = true): Observable<UserModel[]> {
    return this.http
      .get<WebUserModel[]>(this.endPoints['user'].toURL())
      .pipe(map((users) => users.map(this.webUserMapper.mapFrom),
        catchError(this.createErrorHandler(handleError)))
      );
  };

  //status: complete
  getCurrentUser(handleError = true): Observable<UserModel> {
    return this.http
      .get<WebUserModel>(this.endPoints['user/me'].toURL())
      .pipe(map(this.webUserMapper.mapFrom),
        catchError(this.createErrorHandler(handleError)));
  };

  //status: complete
  getUserById(id: Uuid, handleError = true): Observable<UserModel> {
    return this.http
      .get<WebUserModel>(this.endPoints['user/%id%'].toURL(['id', id]))
      .pipe(map(this.webUserMapper.mapFrom), 
        catchError(this.createErrorHandler(handleError)));
  };

  //status: functional, roles missing
  createUser(user: UserModel, handleError = true): Observable<UserModel> {
    return this.http
      .post<WebUserModel>(this.endPoints['user'].toURL(), this.webUserMapper.mapTo(user))
      .pipe(map(user => {
        let tempUser = this.webUserMapper.mapFrom(user);
        return tempUser;
      }), catchError(this.createErrorHandler(handleError)));
  };

  //status: complete
  updateUser(user: UserModel, handleError = true): Observable<void> {
    return this.http
      .put<WebUserModel>(
        this.endPoints['user/%id%'].toURL(['id', user.id]), this.webUserMapper.mapTo(user))
      .pipe(map(() => { }), catchError(this.createErrorHandler(handleError)))
  };

  //status: complete
  updateCurrentUser(user: UserModel, handleError = true): Observable<void> {
    return this.http
      .put<WebUserModel>(
        this.endPoints['user/me'].toURL(), this.webUserMapper.mapTo(user))
      .pipe(map(() => { }), catchError(this.createErrorHandler(handleError)))
  };


  //status: needs testing
  deleteUser(id: Uuid, handleError = true): Observable<void> {
    return this.http
      .delete<WebUserModel>(this.endPoints['user/%id%'].toURL(['id', id]))
      .pipe(map(() => { }), catchError(this.createErrorHandler(handleError)))
  };

  // status: complete
  loginUser(credentials: Credentials, handleError = true): Observable<boolean> {
    return from(this.authUser(credentials))
      .pipe(catchError(this.createErrorHandler(handleError)));
  };

  // status: functional, but needs refactoring
  async authUser(credentials: Credentials): Promise<boolean> {
    return this.oauthService.fetchTokenUsingPasswordFlow(
      credentials.email, credentials.password
    ).then((_token) => {
      //only reached if valid token response
      return true;
    }).catch(err => {
      return false;
    });
  }

  // status: functional, but returned Observable is currently useless
  logoutUser(handleError = true): Observable<boolean> {
    return from(this.oauthService.revokeTokenAndLogout())
      .pipe(
        map(arg => !this.oauthService.hasValidAccessToken()),
        catchError(this.createErrorHandler(handleError))
      )
  };

  // status: complete, pending testing
  signupUser(signupUser: SignupModel, handleError = true): Observable<void> {
    const options = signupUser.key ?
      { params: new HttpParams().set('key', signupUser.key) } : {};
    delete signupUser.key;
    return this.http
      .post<void>(this.endPoints['user/signup'].toURL(), this.webSignupMapper.mapTo(signupUser), options)
      .pipe(catchError(this.createErrorHandler(handleError))
      );
  }

  // status: complete
  isAuthenticated(handleError = true): Observable<boolean> {
    return of(this.oauthService.hasValidAccessToken())
      .pipe(catchError( this.createErrorHandler(handleError) ));
  }

  // status: complete
  requestNewPassword(email: string, handleError = true): Observable<void> {
    return this.http
      .post<string>(
        this.endPoints['user/reset/request'].toURL(), { 'email': email })
      .pipe(map(_ => { }), catchError(this.createErrorHandler(handleError)));
  }

  resetPassword(newPw: PasswordChangeModel, handleError = true): Observable<void> {
    //newPw.key is the URL parameter, not request body
    const options = newPw.key ?
      { params: new HttpParams().set('key', newPw.key) } : {};
    delete newPw.key;
    return this.http
      .post<PasswordChangeModel>(this.endPoints['user/reset'].toURL(), newPw, options)
      .pipe(
        map(_ => { }), 
        catchError(this.createErrorHandler(handleError))
      );
  }

  // status: complete
  getAllGroups(handleError = true): Observable<GroupModel[]> {
    let mapper = (groups: WebGroupModel[]) => groups.map(this.webGroupMapper.mapFrom);
    return this.http
      .get<WebGroupModel[]>(this.endPoints['group'].toURL())
      .pipe(
        map(mapper.bind(this)), 
        catchError(this.createErrorHandler(handleError))
      );
  };

  // status: complete
  createGroup(group: GroupModel, handleError = true): Observable<GroupModel> {
    return this.http
      .post<WebGroupModel>(this.endPoints['group'].toURL(), this.webGroupMapper.mapTo(group))
      .pipe(
        map(this.webGroupMapper.mapFrom.bind(this)),
        catchError(this.createErrorHandler(handleError))
      );
  };

  // status: complete
  getGroupById(id: Uuid, handleError = true): Observable<GroupModel> {
    return this.http
      .get<WebGroupModel>(this.endPoints['group/%id%'].toURL(['id', id]))
      .pipe(
        map(this.webGroupMapper.mapFrom),
        catchError(this.createErrorHandler(handleError))
      );
  };

  // status: complete
  updateGroup(group: GroupModel, handleError = true): Observable<void> {
    return this.http
      .put<WebGroupModel>(this.endPoints['group/%id%'].toURL(['id', group.id]), this.webGroupMapper.mapTo(group))
      .pipe( map(_ => { }),
        catchError( this.createErrorHandler(handleError) )
      );
  };

  // status: needs testing
  deleteGroup(group: GroupModel, handleError = true): Observable<void> {
    return this.http
      .delete<WebGroupModel>(this.endPoints['group/%id%'].toURL(['id', group.id]))
      .pipe(map(_ => { }),
        catchError( this.createErrorHandler(handleError) )  
      );
  };

  // status: complete
  getGroupTrees(handleError = true): Observable<GroupTreeModel[]> {
    return this.http
      .get<WebGroupTreeModel[]>(this.endPoints['group/tree'].toURL())
      .pipe(
        map( webTrees => webTrees.map(this.webTreeMapper.mapFrom)),
        catchError( this.createErrorHandler(handleError) )
      );
  };

  // 
  getKeyExpirationStatus(
    params: [groupId: Uuid, keyId: Uuid],
    handleError = true
  ): Observable<KeyStateModel> {
    return this.http
      .get<KeyStateModel>(
        this.endPoints['group/%id%/keys/expiration?keyid=%keyid%'].toURL(
          ['id', params[0]], ['keyid', params[1]])
      ).pipe(
        catchError( this.createErrorHandler(handleError) )
      );
  }

  //status: complete
  openLock(group: GroupModel, handleError = true): Observable<void> {
    return this.http
      .put<WebGroupModel>(this.endPoints['group/%id%/open'].toURL(['id', group.id]), "")
      .pipe(map(_ => { }),
        catchError( this.createErrorHandler(handleError) )
      );
  }

  getDeviceInfoList(handleError = true): Observable<DeviceInfoModel[]> {
    return this.http
      .get<DeviceInfoModel[]>(this.endPoints['device/info'].toURL())
      .pipe(
        map(webDeviceInfos => webDeviceInfos.map(this.webDeviceMapper.mapFrom)),
        catchError( this.createErrorHandler(handleError) )  
      )
  }

  // status: complete
  getKeyById(id: Uuid, handleError = true): Observable<KeyModel> {
    return this.http
      .get<KeyModel>(this.endPoints['key/%id%'].toURL(['id', id]))
      .pipe(catchError( this.createErrorHandler(handleError) ));
  };

  // status: complete
  getAllUnasignedKeys(customerId: Uuid, handleError = true): Observable<KeyModel[]> {
    let mapper = (keys: WebKeyModel[]) => keys.map(this.webKeyMapper.mapFrom);
    return this.http
      .get<KeyModel[]>(this.endPoints['customer/%id%/unassigned-keys'].toURL(['id', customerId]))
      .pipe(
        map(mapper.bind(this)),
        catchError( this.createErrorHandler(handleError) )
      );
  }

  // status: complete
  getAllKeysForGroup(group: GroupModel, handleError = true): Observable<KeysOfGroupModel> {
    return this.http
      .get<WebKeysOfGroupModel>(this.endPoints["group/%id%/keys"].toURL(["id", group.id]))
      .pipe(
        map(this.webKeysOfGroupMapper.mapFrom.bind(this.webKeysOfGroupMapper)),
        catchError( this.createErrorHandler(handleError) )
      )
  };

  // status: complete
  getKeyQrCode(key: KeyModel, handleError = true): Observable<ImagePngModel> {
    let mapper = new WebImagePngMapper;
    return this.http
      .get(this.endPoints['key/%id%/qr.png'].toURL(["id", key.id]),
        { responseType: 'arraybuffer' })
      .pipe(
        map(mapper.mapFrom), 
        catchError( this.createErrorHandler(handleError) )
      );
  }

  // status: complete
  getAllUsersForGroup(group: GroupModel, handleError = true): Observable<UserModel[]> {
    let mapper = (users: WebUserModel[]) => users.map(this.webUserMapper.mapFrom.bind(this.webUserMapper));
    return this.http
      .get<WebUserModel[]>(this.endPoints["group/%id%/users"].toURL(["id", group.id]))
      .pipe(
        map(mapper),
        catchError( this.createErrorHandler(handleError) )
      );
  };

  // status: complete
  createKey(key: KeyModel, handleError = true): Observable<KeyModel> {
    return this.http
      .post<WebKeyModel>(this.endPoints["key"].toURL(), this.webKeyMapper.mapTo(key))
      .pipe(
        map(this.webKeyMapper.mapFrom.bind(this)),
        catchError( this.createErrorHandler(handleError) )
      );
  };

  // status: complete
  deleteKey(key: KeyModel, handleError = true): Observable<void> {
    return this.http
      .delete<void>(this.endPoints['key/%id%'].toURL(['id', key.id]))
      .pipe(catchError( this.createErrorHandler(handleError) )
      )
  };

  // status: complete
  updateKey(key: KeyModel, handleError = true): Observable<void> {
    return this.http
      .put(
        this.endPoints['key/%id%'].toURL(['id', key.id]),
        this.webKeyMapper.mapTo(key)
      ).pipe(map(_ => { }), catchError( this.createErrorHandler(handleError) ));
  };

  shareKeyMail(params: KeyShareMailModel, handleError = true): Observable<void> {
    return this.http
      .post(
        this.endPoints['key/%id%/share/mail'].toURL(['id', params.keyID]),
        { "recipients": params.recipients },
      ).pipe(map(_ => { }), catchError( this.createErrorHandler(handleError) ));
  }

  // status: complete
  createKeyHolder(keyholder: KeyHolderModel, handleError = true): Observable<KeyHolderModel> {
    return this.http
      .post<WebKeyHolder>(this.endPoints['keyholder'].toURL(), 
                          this.webKeyHolderMapper.mapTo(keyholder))
      .pipe(map(this.webKeyHolderMapper.mapFrom),
            catchError(this.createErrorHandler(handleError))
      );
  }

  updateKeyHolder(keyholder: KeyHolderModel, handleError = true): Observable<KeyHolderModel>{
    return this.http.put<WebKeyHolder>(
      this.endPoints['keyholder/%id%'].toURL(['id', keyholder.id]),
      this.webKeyHolderMapper.mapTo(keyholder)
    ).pipe(
        map(this.webKeyHolderMapper.mapFrom),
        catchError( this.createErrorHandler(handleError) )
    );
  };

  // status: complete
  getKeyholderForCustomerId(customerId: Uuid, handleError = true): Observable<KeyHolderModel[]> {
    let mapper = (khs: WebKeyHolder[]) => 
      khs.map(this.webKeyHolderMapper.mapFrom.bind(this.webKeyHolderMapper));
    return this.http
      .get<WebKeyHolder[]>(this.endPoints['customer/%id%/keyholder'].toURL(['id', customerId]))
      .pipe(
        map(mapper.bind(this)),
        catchError( this.createErrorHandler(handleError) )
      );
  }

  // status: complete
  getKeyholderById(id: Uuid, handleError = true): Observable<KeyHolderModel> {
    return this.http
      .get<WebKeyHolder>(this.endPoints['keyholder/%id%'].toURL(['id', id]))
      .pipe(
        map(this.webKeyHolderMapper.mapFrom.bind(this.webKeyHolderMapper)),
        catchError( this.createErrorHandler(handleError) )
      );
  };

  // status: complete
  getAllKeyholders(handleError = true): Observable<KeyHolderModel[]> {
    let mapper = (khs: WebKeyHolder[]) => 
      khs.map(this.webKeyHolderMapper.mapFrom.bind(this.webKeyHolderMapper));
    return this.http
      .get<WebKeyHolder[]>(this.endPoints['keyholder'].toURL())
      .pipe(
        map(mapper.bind(this)),
        catchError( this.createErrorHandler(handleError) )
      );
  };

  deleteKeyHolder(keyHolderId: Uuid, handleError = true): Observable<void>{
    return this.http
      .delete<void>(this.endPoints['keyholder/%id%'].toURL(['id', keyHolderId]))
      .pipe( catchError( this.createErrorHandler(handleError) ) )
  }

  // status: complete
  deleteKeyRight(id: Uuid, handleError = true): Observable<void> {
    return this.http
      .delete<void>(this.endPoints['keyright/%id%'].toURL(['id', id]))
      .pipe( catchError( this.createErrorHandler(handleError) ) )
  };

  getAllRoles(handleError = true): Observable<RoleModel[]> {
    let mapper = (roles: WebRoleModel[]) => roles.map(this.webRoleMapper.mapFrom);
    return this.http
      .get<WebRoleModel[]>(this.endPoints['roles'].toURL())
      .pipe(
        map(mapper.bind(this)),
        catchError( this.createErrorHandler(handleError) )  
      );
  };

  getAllUserRoles(handleError = true): Observable<UserRoleModel[]> {
    let mapper = (roles: WebUserRoleModel[]) => roles.map(this.webUserRoleMapper.mapFrom);
    return this.http
      .get<WebUserRoleModel[]>(this.endPoints["userrole"].toURL())
      .pipe(
        map(mapper.bind(this)),
        catchError( this.createErrorHandler(handleError) )  
      );
  };

  updateUserRole(userRole: UserRoleModel, handleError = true): Observable<void> {
    return this.http
      .put<WebUserRoleModel>(this.endPoints['userrole/%id%'].toURL(['id', userRole.id]), 
                                this.webUserRoleMapper.mapTo(userRole)
                            )
      .pipe(
        map(_ => { }),
        catchError( this.createErrorHandler(handleError) )
      );
  };

  createUserRole(userRole: UserRoleModel, handleError = true): Observable<UserRoleModel> {
    return this.http
      .post<WebUserRoleModel>(this.endPoints["userrole"].toURL(), 
                                this.webUserRoleMapper.mapTo(userRole)
                             )
      .pipe(
        map(this.webUserRoleMapper.mapFrom.bind(this)),
        catchError( this.createErrorHandler(handleError) )
      );
  };

  deleteUserRole(id: Uuid, handleError = true): Observable<void> {
    return this.http
      .delete<WebRoleModel>(this.endPoints['userrole/%id%'].toURL(['id', id]))
      .pipe(
        map(_ => { }),
        catchError( this.createErrorHandler(handleError) )
      );
  };

  createUserInvitation(invite: InvitationModel, handleError = true): Observable<InvitationModel> {
    return this.http
      .post<WebInvitationModel>(this.endPoints['invitation'].toURL(), this.webInvitationMapper.mapTo(invite))
      .pipe(
        map(this.webInvitationMapper.mapFrom.bind(this.webInvitationMapper)),
        catchError( this.createErrorHandler(handleError) )  
      );
  };

  getAllInvitations(handleError = true): Observable<InvitationModel[]> {
    let mapper = (invites: WebInvitationModel[]) => invites.map(this.webInvitationMapper.mapFrom);
    return this.http
      .get<WebInvitationModel[]>(this.endPoints['invitation'].toURL())
      .pipe(
        map(mapper.bind(this)),
        catchError( this.createErrorHandler(handleError) )
      );
  };

  resendInvitation(id: Uuid, handleError = true): Observable<void> {
    return this.http
      .post<void>(this.endPoints['invitation/%id%/send'].toURL(['id', id]), {})
      .pipe(catchError( this.createErrorHandler(handleError) ));
  };

  getCustomerIdByGroupId(groupId: Uuid, handleError = true): Observable<CustomerModel> {
    return this.http
      .get<CustomerModel>(this.endPoints['group/%id%/customer'].toURL(['id', groupId]))
      .pipe(
        catchError( this.createErrorHandler(handleError) )
      );

  };

  private createErrorHandler<T>(handleError: boolean = true): ErrorHandler<T> {
    return (error: HttpErrorResponse) => {
      if (error.status == 403){
        // if the BE tells us something is forbidden, we asume a logout is needed,
        // due to an outdated/invalid auth token in the browser's local storage
        this.logoutUser();
        this.notificationService.notifyError("Access forbidden. Please log in again.");
      }
      else {
        this.notificationService.notifyUserOfHttpError(error);
      }
      if (!handleError) {
        return throwError(error);
      }
      return new Observable(subscriber => {subscriber.complete()});
    };
  }
}
