<app-data-table matSort 
    [dataSource]="this.keyTableDataSource" 
    [editableFields]="true"
    [addButtonEnabled]="false"
    [editButtonEnabled]="false"
    [columns]="this.keyTableColumns"
    (deleteRows)="confirmKeysDelete($event)"
>
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{"Name" | translate}}
        </th>
        <td mat-cell *matCellDef="let key">
            {{key.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="keyTeeth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{"Code" | translate}}
        </th>
        <td mat-cell *matCellDef="let key">
            <ng-container ngSwitch="{{key.generic.type}}">
                <ng-container *ngSwitchCase=this.ComydoKeyType.Plate>
                    {{key.generic.licensePlate}}
                </ng-container>
                <ng-container *ngSwitchDefault>
                    {{key.keyTeeth}}
                </ng-container>
            </ng-container>
        </td>
    </ng-container>

    <tr mat-row
        *matRowDef="let row; columns: this.keyTableColumnsAll;"
    ></tr>

</app-data-table>