import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { CreateGroupUsecase } from 'src/app/core/useCases/group.usecases';
import { UiGroupModel } from '../models/ui-group.model';
import { UiGroupMapper } from '../mappers/ui-group.mapper';
import { GroupTreeService } from 'src/app/core/services/group-tree.service';
import { GroupModel } from 'src/app/core/models/group.model';

interface CreateGroupDialogDataModel {
    parent: GroupModel    
}

@Component({
    selector: 'app-create-group-dialog',
    templateUrl: 'create-group.dialog.html',
    styleUrls: ['create-group.dialog.scss']
})
export class CreateGroupDialogComponent {
    newGroupForm: FormGroup;
    uiGroupMapper= new UiGroupMapper();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: CreateGroupDialogDataModel,
        private createGroupUsecase: CreateGroupUsecase,
        public formBuilder: FormBuilder,
        private groupTreeService: GroupTreeService,
    ) { 
        this.newGroupForm = this.formBuilder.group({
            name: ['', Validators.required],
            description: ['', ]
          });
    }

    createGroup() {
        let newGroup: UiGroupModel = {
            name: this.newGroupForm.value.name,
            parentId: this.data.parent.id,
            description: this.newGroupForm.value.description,
            attachedLockId: undefined,
            frozen: false,
            publicId: '',
            generic: {},
        };
        this.createGroupUsecase
            .execute(this.uiGroupMapper.mapFrom(newGroup))
            .subscribe(() => {
                this.groupTreeService.loadGroupTree();
            });
    }
}
