import { Mapper } from "src/app/core/base/mapper";
import { ImagePngModel } from "src/app/core/models/image-png.model";
import { convertABtoB64s, convertB64toAB } from "src/app/shared/util";

export class WebImageB64sMapper extends Mapper<string, ImagePngModel>{
    constructor(){
        super();
    };

    public mapFrom(param: string): ImagePngModel {
        return {
            image: convertB64toAB(param)
        }
    }

    public mapTo(param: ImagePngModel): string{
        return convertABtoB64s(param.image)
    }
}
