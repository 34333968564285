import { Mapper } from "src/app/core/base/mapper";
import { KeyModel } from "src/app/core/models/key.model";
import { ComydoKeyType } from "src/app/core/types/KeyType";
import { UiKeyModel } from "../models/ui-key.model";
import { UiKeyRightMapper } from "./ui-key-right.mapper";
var shajs = require('sha.js')


export class UiKeyMapper extends Mapper<UiKeyModel, KeyModel>{
    krMapper = new UiKeyRightMapper();

    mapFrom(param: UiKeyModel): KeyModel {
        let uiKeyData = { ...param as any };
        uiKeyData.keyRights = [];
        for (let kr of param.keyRights) {
            uiKeyData.keyRights.push(this.krMapper.mapFrom(kr))
        }
        //copy hashed plate to keyTeeth
        if (uiKeyData.generic.type == ComydoKeyType.Plate) {
            uiKeyData.keyTeeth = 
                shajs('sha256').update(uiKeyData.generic.licensePlate)
                .digest('hex');
        }

        let key = uiKeyData as KeyModel;
        if (!key.keyHolder) {
            delete key.keyHolder;
        }

        return key;
    }

    mapTo(param: KeyModel): UiKeyModel {
        let paramAny = { ...param as any };
        paramAny.keyRights = [];
        param.keyRights.forEach(kr => {
            paramAny.keyRights.push(this.krMapper.mapTo(kr))
        })
        let ui_key = paramAny as UiKeyModel;
        return ui_key;
    }
}
