import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.scss'],
})
export class NotFoundPageComponent {
  constructor(private router: Router) { }

  goBackHome() {
    this.router.navigateByUrl('/home');
  }
}
