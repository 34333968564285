import { Mapper } from 'src/app/core/base/mapper';
import { RoleModel } from 'src/app/core/models/role.model';
import { UiRoleModel } from '../models/ui-role.model';


export class UiRoleMapper extends Mapper<UiRoleModel, RoleModel>{
    mapFrom(param: UiRoleModel): RoleModel {
        return param as RoleModel
    }

    mapTo(param: RoleModel): UiRoleModel {
        return param as UiRoleModel
    }
}
