<div class="layout-container">
    <div class="layout">
        <app-login-component *ngIf="!keyParam; else signup"></app-login-component>

        <ng-template #signup>
            <app-signup-component [keyParam]="this.keyParam"></app-signup-component>
        </ng-template>

    </div>
</div>
