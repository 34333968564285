import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiInvitationModel } from '../models/ui-invitation.model';
import { GetAllUsersUsecase } from 'src/app/core/useCases/user.usecases';
import { DomSanitizer } from '@angular/platform-browser';
import { UiUserModel } from '../models/ui-user.model';
import { UiUserMapper } from '../mappers/ui-user.mapper';

@Component({
  selector: 'app-add-role-detail-dialog',
  templateUrl: 'add-role.dialog.html',
  styleUrls: ['./add-role.dialog.scss'],
})
export class AddRoleDialogComponent {
  public newInvite!: UiInvitationModel;
  public allUsers= Array<UiUserModel>();
  private groupUsers= Array<UiUserModel>();
  private uiUserMapper = new UiUserMapper(this.sanitizer);
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { invite: UiInvitationModel, groupUsers: Array<UiUserModel>},
    public userDialogRef: MatDialogRef<AddRoleDialogComponent>,
    private getAllUsers: GetAllUsersUsecase,
    private sanitizer: DomSanitizer,
  ) {
    this.newInvite = data.invite;
    this.groupUsers = data.groupUsers;
    this.getAllUsers.execute().subscribe( array => {
      this.allUsers = array.map(this.uiUserMapper.mapTo.bind(this.uiUserMapper));
      //remove all those users who are already in the group
      for (var i = 0; i < this.groupUsers.length; i++){
        var deleteIndex = this.allUsers.indexOf(this.groupUsers[i]);
        this.allUsers.splice(deleteIndex, 1);
    }});
  }

  inviteUserWithInputData() {
    this.userDialogRef.close({ event: 'close', data: this.newInvite });
  }

  closeDialog() {
    this.userDialogRef.close({ event: 'close', data: undefined });
  }
}
