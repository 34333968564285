import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { Subscription } from 'rxjs';
import { GroupModel } from 'src/app/core/models/group.model';
import { Uuid } from 'src/app/core/types/Uuid';
import { GetGroupByIdUsecase } from 'src/app/core/useCases/group.usecases';
import { sideBarMinWidth } from 'src/global';
import { UiGroupMapper } from '../../mappers/ui-group.mapper';
import { UiGroupModel } from '../../models/ui-group.model';

enum TabType {
  Keys,
  Roles,
  GroupDetails,
}

enum SubPageType{
  Customer,
  Group
}

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy, AfterViewInit {
  routerParams!: Subscription;
  routerEvents!: Subscription;
  TabType = TabType;
  currentTab: TabType = TabType.GroupDetails;
  SubPageType = SubPageType;
  currentPage?: SubPageType;
  //currentUrl!: String;

  selectedGroup?: UiGroupModel;
  groupMapper = new UiGroupMapper();

  sideBarWidth: number = sideBarMinWidth;
  sideBarStyleWidth: string =  this.sideBarWidth + "px";
  beforeResizeX!: number;
  resizing: boolean = false;
  

  
  constructor(
    public formBuilder: FormBuilder,
    private getGroupUseCase: GetGroupByIdUsecase,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.routerEvents = this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this.updateTabsHeader.bind(this)(e.url);
      }
    })
    this.routerParams = this.route.params.subscribe(
      (params: { [x: string]: Uuid; }): void => {
        this.onPageLoaded(params['groupid']);
      });
    this.updateTabsHeader(this.router.url);
  }

  onPageLoaded(groupId: Uuid) {
    if (groupId) {
      this.getGroupUseCase.execute(groupId).subscribe(
        this.onGroupSelected.bind(this)
      )
    }
  }

  onGroupSelected(group: GroupModel) {
    this.selectedGroup = this.groupMapper.mapTo(group);
  }

  ngAfterViewInit(){
    //this.routeSubscription.unsubscribe();
  }

  ngOnDestroy(){
    this.routerParams.unsubscribe();
    this.routerEvents.unsubscribe();
  }

  startResize($event: MouseEvent){
    this.resizing = true;
    $event.preventDefault();
  }

  onMouseMove($event: MouseEvent){
    if (this.resizing){
      $event.preventDefault();
      this.sideBarWidth = $event.clientX;
      if (this.sideBarWidth < sideBarMinWidth){
        this.sideBarWidth = sideBarMinWidth;
      }
      this.sideBarStyleWidth = this.sideBarWidth + "px";
      this.sideBarStyleWidth = this.sideBarStyleWidth;
    }

  }

  finishResize($event: MouseEvent){
    this.resizing = false;
  }

  updateTabsHeader(url: String) {
    if ( url.startsWith('/home/customer') ){
      this.currentPage = SubPageType.Customer;
    } else if ( url.startsWith('/home/group') ){
      this.currentPage = SubPageType.Group;
    }

    if (url?.endsWith("keys")) {
      this.currentTab = TabType.Keys;
    }
    else if (url?.endsWith("roles")) {
      this.currentTab = TabType.Roles;
    }
    else if (url?.endsWith("groupdetails")) {
      this.currentTab = TabType.GroupDetails;
    }
  }

}
