import { Mapper } from 'src/app/core/base/mapper';
import { GroupTreeNodeModel } from 'src/app/core/models/group-tree-node.model';
import { WebGroupTreeNodeModel } from '../models/web-group-tree-node.model';

export class WebTreeNodeMapper extends Mapper<WebGroupTreeNodeModel, GroupTreeNodeModel>{
    mapFrom(param: WebGroupTreeNodeModel): GroupTreeNodeModel{
        let childArray: GroupTreeNodeModel[] = [];
        param.children.forEach((child) => {
            childArray.push(this.mapFrom(child))
        });
        let returnGroupTreeNodeModel:GroupTreeNodeModel = {
            id: param.id,
            name: param.name,
            description: param.description,
            frozen: param.frozen,
            parentId: param.parentId,
            publicId: param.publicId,
            generic: param.generic,
            children: childArray,
            attachedLockId: param.attachedLockId ? param.attachedLockId : undefined,
            permissions: param.permissions,
        }
        return returnGroupTreeNodeModel;
    }

    mapTo(param: GroupTreeNodeModel): WebGroupTreeNodeModel{
        let childArray: WebGroupTreeNodeModel[] = [];
        param.children.forEach((child) => {
            childArray.push(this.mapTo(child))
        });
        let returnGroupNodeEntity: WebGroupTreeNodeModel = {
            id: param.id,
            name: param.name,
            description: param.description,
            frozen: param.frozen,
            parentId: param.parentId,
            publicId: param.publicId,
            generic: param.generic,
            children: childArray,
            attachedLockId: param.attachedLockId ? param.attachedLockId : undefined,
            permissions: param.permissions,
        }
        return returnGroupNodeEntity;
    }
}
