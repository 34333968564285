import { GroupTreeModel } from 'src/app/core/models/group-tree.models';
import { Mapper } from 'src/app/core/base/mapper';
import { WebGroupTreeModel } from '../models/web-group-tree.model';
import { WebTreeNodeMapper } from './web-group-tree-node.mapper';


export class WebTreeMapper extends Mapper<WebGroupTreeModel, GroupTreeModel>{
    mapFrom(param: WebGroupTreeModel): GroupTreeModel{
        let nodeMapper = new WebTreeNodeMapper();
        return {
            customer: param.customer,
            root: nodeMapper.mapFrom(param.root),
        }
    }

    mapTo(param: GroupTreeModel): WebGroupTreeModel{
        let nodeMapper = new WebTreeNodeMapper();
        return {
            customer: param.customer,
            root:  nodeMapper.mapTo(param.root)
        }
    }
}
