import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


export abstract class AlertModel {
    public header!: string;
    public message!: string;
    public button!: string;
}

@Component({
    selector:'app-alert',
    templateUrl:'alert.dialog.html'
})
export class AlertDialogComponent{
    constructor(
        @Inject(MAT_DIALOG_DATA) public alert_data: AlertModel,
        public dialog: MatDialog,
    ) {
    }
}



