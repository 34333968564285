import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private snackBar: MatSnackBar) { }

  notify(message: string){
    this.snackBar.open(message, "OK", {
      duration: 2000
    });
  }

  notifyError(message: string){
    this.snackBar.open(message, "OK", {
      duration: 0
    });
  }

  notifyUserOfHttpError(error: HttpErrorResponse){
    if (environment.showErrorDetails == true){
      let errorString = `Error: ${JSON.stringify(error)}`;
      this.snackBar.open(errorString, "OK");
      console.error(errorString);
      return;
    }
    this.snackBar.open("An Error occured. " +
                       "Please contact info@comydo.com if the error persists.");
  }


}
