import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Backend } from "../base/backend";
import { UseCase } from "../base/use-case";
import { CustomerModel } from "../models/customer.model";
import { GroupModel } from "../models/group.model";
import { Uuid } from "../types/Uuid";

@Injectable({
    providedIn: 'root'
  })
  export class GetCustomerByGroupIdUsecase implements UseCase<Uuid, CustomerModel> {
  
    constructor(private userRepository: Backend) { }
  
    execute(params: Uuid): Observable<CustomerModel> {
      return this.userRepository.getCustomerIdByGroupId(params);
    }
  }
