import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { AlertDialogComponent } from '../presentation/dialogs/alert.dialog';

//import { OAuthSuccessEvent } from 'angular-oauth2-oidc';


/*
    Adds functionality to check if the backend is online.
    If the backend is not online, it raises an alert message.
    The functionality gets called from the app.component
    constructor.
*/
@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {

    constructor(
        private http: HttpClient,
        public dialog: MatDialog,
    ) { }

    async checkBackendOnline() {
        try {
            await this.http.get(`${environment.apiUrl}healthz/`).toPromise();
        } catch (err) {
            this.showBackendDownAlert();
        }
    }

    async showBackendDownAlert() {
        this.dialog.open(AlertDialogComponent, {
                data: {
                    header: 'Problem detected',
                    message: 'Couldn\'t connect to the backend server.',
                    button: ['OK']
                }
            }
        )
    }
}
