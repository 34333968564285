//don't blame me, blame JavaScript...
import { FormGroup } from '@angular/forms';

export function convertB64toAB(base64string: string): ArrayBuffer{
  let binary_string = atob(base64string);
  let len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export function convertABtoB64s(ab: ArrayBuffer): string {
  let typeArray = new Uint8Array(ab);
  const STRING_CHAR = typeArray.reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, '');
  return btoa(STRING_CHAR);
}

export function cloneDeep<T>(obj: T): T {
    return Array.isArray(obj)
      ? obj.map(item => cloneDeep(item))
      : obj instanceof Date
        ? new Date(obj.getTime())
        : obj && typeof obj === 'object'
          ? Object.getOwnPropertyNames(obj).reduce((o: any, prop) => {
            o[prop] = cloneDeep((obj as any)[prop]);
            return o;
          }, {})
          : obj;
}

export function MatchFormField(controlName: string, matchingControlName: string){
  //password match check
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
          return;
      }
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
