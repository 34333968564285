import { Mapper } from 'src/app/core/base/mapper';
import { WebKeyModel, WebKeysOfGroupModel } from "../models/web-key.model";
import { KeysOfGroupModel as KeysOfGroupModel } from 'src/app/core/models/keys-of-group.model';
import { WebKeyMapper } from './web-key.mapper';
import { KeyModel } from 'src/app/core/models/key.model';


export class WebKeysOfGroupMapper extends Mapper<WebKeysOfGroupModel, KeysOfGroupModel>{
    keyMapper = new WebKeyMapper()
    mapFrom(param: WebKeysOfGroupModel): KeysOfGroupModel{
        return {
            "inherited": param.inherited.map(this.mapKeyFrom.bind(this)),
            "direct": param.direct.map(this.mapKeyFrom.bind(this))
        }
    }

    mapKeyFrom(key: WebKeyModel): KeyModel {
        return this.keyMapper.mapFrom(key);
    }

    mapKeyTo(key: KeyModel): WebKeyModel {
        return this.keyMapper.mapTo(key)
    }

    mapTo(param: KeysOfGroupModel): WebKeysOfGroupModel{
        return {
            "inherited": param.inherited.map(this.mapKeyTo.bind(this)),
            "direct": param.direct.map(this.mapKeyTo.bind(this))
        }
    }
}
