import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { IsAuthenticatedUsecase } from 'src/app/core/useCases/credentials.usecases';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
     private isAuthenticated: IsAuthenticatedUsecase,
     private router: Router) 
  {}

  async canActivate(route: ActivatedRouteSnapshot, 
                    state: RouterStateSnapshot): Promise<boolean> {
    return this.isAuthenticated.execute().toPromise().then((isLoggedin) => {
      if (!isLoggedin) {
        this.router.navigateByUrl('/login');
      }
      return isLoggedin;
    });
  }
}
