<form class="change-pw-form" [formGroup]="changePasswordForm" (ngSubmit)="submitForm()">

    <mat-form-field position="floating" appearance="fill">
        <mat-label for="password" position="floating">{{ 'Current Password' | translate }}</mat-label>
        <input matInput type="password" class="validate" formControlName="password">
    </mat-form-field>

    <mat-form-field position="floating" appearance="fill">
        <mat-label for="new_password" position="floating">{{ 'New Password' | translate }}</mat-label>
        <input matInput type="password" class="validate" formControlName="newPassword">
    </mat-form-field>

    <mat-form-field position="floating" appearance="fill">
        <mat-label for="new_password2" position="floating">{{ 'Repeat New Password' | translate }}</mat-label>
        <input matInput type="password" class="validate" formControlName="confirmNewPassword">
    </mat-form-field>

    <div *ngIf="changePasswordForm.errors?.['pwsNotSame'] && (changePasswordForm.touched || changePasswordForm.dirty)" 
        class="cross-validation-error-message alert alert-danger"
    >
        {{'The Passwords do not match' | translate }}
    </div>

    <button class="change-button" color="primary" mat-raised-button type="submit" [disabled]="!changePasswordForm.valid">
        {{ 'Change Password' | translate }}
    </button>

</form>
