import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/presentation/services/notification.service';
import { MatchFormField } from 'src/app/shared/util';
import { maxPasswordLength, minPasswordLength } from 'src/global';
import { SignupUserUsecase } from '../../../../../core/useCases/user.usecases';
import { UiSignupMapper } from '../../../../mappers/ui-signup.mapper';
import { UiSignupModel } from '../../../../models/ui-signup.model';


@Component({
  selector: 'app-signup-component',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  @Input() keyParam!: string;

  user?: UiSignupModel;
  form: FormGroup;
  private uiSignupMapper = new UiSignupMapper();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private signupUserUsecase: SignupUserUsecase,
    private notificationService: NotificationService

  ) {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      profession: [''],
      comment: [''],
      company: [''],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(minPasswordLength),
        Validators.maxLength(maxPasswordLength)],
      )],
      passwordRepeat: ['', Validators.compose([
        Validators.required,
        Validators.minLength(minPasswordLength),
        Validators.maxLength(maxPasswordLength)],
      )],
    },
    {
      validator: MatchFormField('password', 'passwordRepeat')
    });
  }

  get getFormControls(){
    return this.form.controls;
  }

  ngOnInit() { }

  submitForm() {
    this.user = this.form.value;
    this.user!.key = this.keyParam;
    this.signupUserUsecase.execute(this.uiSignupMapper.mapFrom(this.user!)).subscribe(
      () => {
        this.notificationService.notify("Success");
        this.goToLogin();
      },
      error => {
        this.notificationService.notifyUserOfHttpError(error.error);
      }
    );
  }

  goToLogin() {
    this.router.navigateByUrl('/');
  }
}
