import { Mapper } from 'src/app/core/base/mapper';
import { KeyModel } from 'src/app/core/models/key.model';
import { WebKeyModel } from '../models/web-key.model';


export class WebKeyMapper extends Mapper<WebKeyModel, KeyModel>{
    mapFrom(param: WebKeyModel): KeyModel{
        return param as KeyModel
    }

    mapTo(param: KeyModel): WebKeyModel{
        delete param['permissions'];
        

        let web_key: WebKeyModel = {
            id: param.id,
            name: param.name,
            keyTeeth: param.keyTeeth,
            keyHolder: param.keyHolder,
            generic: param.generic,
            keyRights: param.keyRights
        }

        // this will make the BE generate a random keyTeeth
        if (param.keyTeeth == ""){
            web_key.keyTeeth = null;
        }

        // if the keyHolder should be deleted we need to explicitly set it to null
        if (!param.keyHolder){
            web_key.keyHolder=null;
        }

        return web_key;
    }
}
