import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { GroupModel } from 'src/app/core/models/group.model';
import { UserModel } from 'src/app/core/models/user.model';
import { Uuid } from 'src/app/core/types/Uuid';
import { GetGroupByIdUsecase } from 'src/app/core/useCases/group.usecases';
import { CreateInvitationUsecase, GetAllInvitationsUsecase, ResendInvitationUsecase } from 'src/app/core/useCases/invitation.usecases';
import { CreateUserRoleUsecase, DeleteUserRoleUsecase, GetAllRolesUsecase } from '../../../core/useCases/roles.usecases';
import { GetAllUsersForGroupUsecase, GetAllUsersUsecase } from '../../../core/useCases/user.usecases';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete.dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm.dialog';
import { UiInvitationMapper } from '../../mappers/ui-invitation-mapper';
import { UiUserMapper } from '../../mappers/ui-user.mapper';
import { UiGroupModel } from '../../models/ui-group.model';
import { UiInvitationModel } from '../../models/ui-invitation.model';
import { UiRoleModel } from '../../models/ui-role.model';
import { UiUserModel } from '../../models/ui-user.model';
import { UiUserRoleModel } from '../../models/ui-user-role.model';
import { UiRoleMapper } from '../../mappers/ui-role.mapper';
import { NotificationService } from '../../services/notification.service';

enum SubpageType {
  Roles,
  AddUser
}
@Component({
  selector: 'app-users-group',
  templateUrl: './roles.page.html',
  styleUrls: ['./roles.page.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class RolesPageComponent implements OnInit {
  SubpageType = SubpageType;
  currentSubpage: SubpageType = SubpageType.Roles;
  selectedGroup!: UiGroupModel;
  selectedGroupId?: Uuid;
  selectedUser?: UiUserModel;
  newRole?: UiRoleModel;
  roles: UiRoleModel[] = [];
  dataSource: MatTableDataSource<UiUserModel> = new MatTableDataSource();
  private uiRoleMapper = new UiRoleMapper();
  private uiUserMapper = new UiUserMapper(this.sanitizer);
  groupSubscription?: Subscription;
  private uiInvitationMapper = new UiInvitationMapper();

  allUsers: UserModel[] = [];
  userStrings: string[] = [];
  newRoleCtrl = new FormControl();
  filterdUserStrings!: Observable<string[]>;

  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  constructor(
    public dialog: MatDialog,
    private createInvite: CreateInvitationUsecase,
    //TODO (mo): implement resending of invites
    //private getInvites: GetAllInvitationsUsecase,
    //private resendInvite: ResendInvitationUsecase,
    private getGroupUsecase: GetGroupByIdUsecase,
    private getUsersForGroupUsecase: GetAllUsersForGroupUsecase,
    private getAllUsersUsecase: GetAllUsersUsecase,
    private getAllRoles: GetAllRolesUsecase,
    private createUserRoleUsecase: CreateUserRoleUsecase,
    private deleteUserRoleUsecase: DeleteUserRoleUsecase,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private notificationService: NotificationService,
  ) { }



  ngOnInit() {
    this.route.params.subscribe(p => this.loadDataSource(p["groupid"]));
    this.getAllRoles.execute().subscribe(
      roles => roles.forEach(r => {
            this.roles.push(this.uiRoleMapper.mapFrom(r))
          }
        )
    )
    this.getAllUsersUsecase.execute().subscribe(users => {
      this.allUsers = users;
      this.setUserStrings(users);
    });
    this.filterdUserStrings = this.newRoleCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterUser(value)),
    );
  }

  setSubpageType(newSubpageType: SubpageType) {
    if (!this.selectedGroup.permissions!.addRole) {
      this.notificationService.notifyError("No permissions to add roles.")
      return;
    }
    this.currentSubpage = newSubpageType;
  }

  private setUserStrings(users: UserModel[]) {
    for (let u of users) {
      let userString = `${u?.email} (${u?.firstName} ${u?.lastName})`;
      this.userStrings.push(userString);
    }
  }

  private _filterUser(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.userStrings.filter(
      us => us.toLocaleLowerCase().includes(filterValue)
    );
  }

  loadDataSource(groupId: Uuid) {
    this.selectedGroupId = groupId;
    this.getGroupUsecase.execute(groupId).subscribe(group => {
      this.selectedGroup = group;
      this.onGroupSelected(group);
    })
  }

  getRolebyId(id: Uuid): UiRoleModel | undefined {
    const role = this.roles.find(r => r.id === id)
    return role
  }

  onGroupSelected(group: GroupModel) {
    this.getUsersForGroupUsecase.execute(group).subscribe(groupUsers => {
      this.dataSource.data = groupUsers.map(
        this.uiUserMapper.mapTo.bind(this.uiUserMapper));
      //remove users with role from suggestions list for new role
      // groupUsers.forEach(gu => {
      //   this.userStrings = this.userStrings.filter(f => !f.includes(gu.email))
      // });
    });
  }

  removeRole(role: UiRoleModel) {
    this.confirmRoleDelete(role.id!);
  }

  confirmRoleDelete(roleIdToDel: Uuid) {
    const ref = this.dialog.open(ConfirmDeleteDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: "Role"
    });
    ref.afterClosed()
      .pipe(filter(result => result))
      .subscribe(
        _ => {
          this.deleteUserRoleUsecase.execute(roleIdToDel).subscribe();
          this.loadDataSource(this.selectedGroupId!);
        }
      )
  }

  changeGroup(groupId: Uuid) {
    this.router.navigateByUrl('/home/group/' + groupId + '/roles');
  }

  inviteUser(mail: string, roleId: Uuid) {
    let invite: UiInvitationModel = {
      email: mail,
      roleId: roleId,
      group: this.selectedGroupId!
    }
    this.createInvite.execute(
      this.uiInvitationMapper.mapFrom(invite)
    ).subscribe(_ => { },
      error => {
        if (error.status == 400) {
          //invitation with this e-mail already exists
          this.notificationService.notifyError("Invitation with this e-mail already exists.")
          //TODO: resend invite (or scratch resending invites)?
        }
        else {
          console.error(error);
        }
      });
  }

  createRole(userString: string, roleId: Uuid) {
    let mail = userString.match(/([^\s]+)/);
    let extracted_mail: string;
    if (mail && mail.length > 1) {
      extracted_mail = mail[1];
    }
    else {
      alert("email not valid");
      return;
    }
    // get the user with the given email, this assumes unique emails
    let user = this.allUsers.filter(u => u.email === extracted_mail);
    if (user[0] == undefined) {
      let confirmRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          header: 'User Not Found',
          message: `User with address ${extracted_mail} not found. Shall we send an invite per email?`,
          no_action_button: 'Cancel',
          confirm_action_button: 'Invite'
        }
      })
      confirmRef.afterClosed()
        .pipe(filter(result => result),) //check user confirmation
        .subscribe(
          this.inviteUser.bind(this, extracted_mail, roleId)
        );
      return;
    }
    if (roleId && user) {
      let uiUserRole: UiUserRoleModel = {
        id: Uuid(),
        groupId: this.selectedGroupId!,
        userId: user[0].id,
        roleId: roleId,
      }
      this.createUserRoleUsecase.execute(uiUserRole).subscribe(_ => { },
        error => {
          console.error(error);
        });
    }
    this.loadDataSource(this.selectedGroupId!);
    this.currentSubpage = SubpageType.Roles;
  }
}
