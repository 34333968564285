import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DeviceInfoModel } from 'src/app/core/models/device.models';
import { GetDeviceInfoListUsecase } from 'src/app/core/useCases/device.usecases';
import { AdminDialogComponent } from './admin.dialog';

@Component({
  selector: 'app-admin',
  styleUrls: ['./admin.page.scss'],
  templateUrl: './admin.page.html',
})
export class AdminPageComponent implements OnInit {
  displayedColumns: string[] = [
    'serialNr',
    'name',
    'deviceMqttId',
    'uptime1w',
    'uptime24h',
    'uptime1h'
  ];
  dataSource: MatTableDataSource<DeviceInfoModel> = new MatTableDataSource();
  filter: string = "";
  deviceInfos: DeviceInfoModel[] = [];

  @ViewChild(MatSort, { static: true }) sort?: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator?: MatPaginator;

  constructor(
      public dialog: MatDialog,
      private getDeviceInfoListUseCase: GetDeviceInfoListUsecase
    ) { }

  ngOnInit() {
    this.updateDataSource();
    this.dataSource.sort = this.sort || null;             //NOTE(keep those nulls for integration reasons)
    this.dataSource.paginator = this.paginator || null;
  }

  updateDataSource(){
    this.getDeviceInfoListUseCase.execute().subscribe( devInfos => {
      this.deviceInfos = devInfos;
      this.dataSource.data = this.deviceInfos;
    });
  }

  updateFilter(){
    this.dataSource.filter = this.filter;
  }

  public calcUptimeColor(uptimePercent: number | null): string {
    if (uptimePercent == null) {
      return '';
    }
    if (uptimePercent >= 0.9) return "green";
    if (uptimePercent >= 0.8) return "orange";
    return "red";
  }

  openDialog(device: DeviceInfoModel) {
    this.dialog.open(AdminDialogComponent, {
      data: { device: device },
    });
  }
}
