import { SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Mapper } from 'src/app/core/base/mapper';
import { UserModel } from 'src/app/core/models/user.model';
import { DEFAULT_PROFILE_IMAGE } from 'src/global';
import { UiUserModel } from '../models/ui-user.model';
import { UiImagePngMapper } from './ui-image-png.mapper';


export class UiUserMapper extends Mapper<UiUserModel, UserModel>{
    constructor(private sanitizer: DomSanitizer) {
        super();
    }

    uiImagePngMapper = new UiImagePngMapper(this.sanitizer);

    mapFrom(param: UiUserModel): UserModel {
        let user: UserModel = {
            id: param.id,
            email: param.email,
            firstName: param?.firstName,
            lastName: param?.lastName,
            profession: param?.profession,
            image: param.image ? this.uiImagePngMapper.mapFrom(param.image) : undefined,
            comment: param?.comment,
            company: param?.company,
            roles: param?.roles,
        }
        if (param.permissions) {
            user.permissions = param.permissions;
        }
        return user;
    }

    serializeImage(image: SafeUrl): string {
        if (!image) {
            throw new Error('invalid image argument');
        }
        let str = "data:image/png;base64," + image;
        let sanitized = this.sanitizer.bypassSecurityTrustResourceUrl(str);
        let sanitizer_output = this.sanitizer.sanitize(SecurityContext.URL, sanitized);
        if (!sanitizer_output) {
            throw new Error('failed to sanitize image');
        }
        return sanitizer_output;
    }

    mapTo(param: UserModel): UiUserModel {
        let user: UiUserModel = {
            id: param.id,
            email: param.email,
            firstName: param?.firstName,
            lastName: param?.lastName,
            profession: param?.profession,
            image: undefined,
            comment: param?.comment,
            company: param?.company,
            roles: param!.roles,
        }
        if (param.image) {
            user.image = this.uiImagePngMapper.mapTo(param.image)
        }
        if (param.permissions) {
            user.permissions = param.permissions;
        }
        return user;
    }
}
