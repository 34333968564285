import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './local-storage.service';

export abstract class Language{
  code!: string;
  name!: string;
}
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private availableLanguages: Language[] = [{
      code: 'en',
      name: 'English',
    }, {
      code: 'de',
      name: 'German',
    }
  ];
  public currentLanguage$ = new BehaviorSubject(this.availableLanguages[0]);
  defaultLanguage = 'en';

  constructor(private storage: LocalStorageService, private translateService: TranslateService) { }

  saveLanguage(langCode: string) {
    this.translateService.use(langCode);
    this.storage.set('lang', langCode);
    this.currentLanguage$.next(this.getLanguageFromCode(langCode))
  }

  //returns stored language, otherwise first available language
  getLanguage(): Observable<Language> {
    let promise = this.storage.get('lang').then(storedLang => {
      if (!storedLang) {
        return this.getLanguageFromCode(this.defaultLanguage)
      }
      return this.getLanguageFromCode(storedLang)
    });
    return from(promise);
  }

  getLanguageFromCode(code: string): Language{
    return this.availableLanguages.filter( (item) => item.code === code )[0]
  }

  getAvailableLanguages(){
    return this.availableLanguages
  }

  getBrowserLanguage(): string{
    return navigator.language
  }
}
