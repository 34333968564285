import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { DialogsModule } from '../dialogs/dialogs.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DataTableComponent } from './data-table/data-table.component';
import { GroupDetailsComponent } from './group-details/group-details.component';
import { GroupTreeComponent } from './group-tree/group-tree.component';
import { PasswordRequestResetComponent } from './password-reset/pw-request-reset.component';
import { PasswordResetComponent } from './password-reset/pw-reset.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { KeyWithoutKeyRightEditorComponent } from './key-without-key-right-editor/key-without-key-right-editor.component';
import { KeyHolderEditorComponent } from './key-holder-editor/key-holder-editor.component';


@NgModule({
    imports: [
        CommonModule,
        DialogsModule,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatRadioModule,
        MatSelectModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTableModule,
        MatTreeModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        ChangePasswordComponent,
        DataTableComponent,
        GroupTreeComponent,
        GroupDetailsComponent,
        PasswordRequestResetComponent,
        PasswordResetComponent,
        KeyWithoutKeyRightEditorComponent,
        KeyHolderEditorComponent
    ],
    exports: [
        ChangePasswordComponent,
        DataTableComponent,
        GroupTreeComponent,
        GroupDetailsComponent,
        PasswordRequestResetComponent,
        PasswordResetComponent,
        KeyWithoutKeyRightEditorComponent,
        KeyHolderEditorComponent
    ],
})
export class ComponentsModule { }
