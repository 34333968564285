import { PermissionUserModel } from "src/app/core/models/permission.models";
import { Uuid } from "src/app/core/types/Uuid";
import { DEFAULT_PROFILE_IMAGE } from "src/global";
import { UiImagePngModel } from "./ui-image-png.model";
import { UiRoleModel } from "./ui-role.model";
export abstract class UiUserModel {
    id!: Uuid;
    email!: string;
    firstName?: string;
    lastName?: string;
    profession?: string;
    image?: UiImagePngModel;
    comment?: string;
    company?: string;
    permissions?: PermissionUserModel;
    roles?: Array<UiRoleModel>;
}

export function emptyUiUser(): UiUserModel {
    return {
        id: Uuid(), 
        email: "", 
        firstName: "",
        lastName: "",
        profession: "",
        company: "",
        comment: "",
        image: undefined,
        roles: [],
    };
}
