import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-detail-dialog',
  templateUrl: 'admin.dialog.html',
})
export class AdminDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}
