import { HttpClient, HttpClientModule, HttpResponse } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OAuthModule, OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Backend } from './core/base/backend';
import { DataModule } from './data/data.module';
import { WebBackend } from "./data/webBackend/web-backend";
import { CookieConsentService } from './services/cookie-consent.service';
import { SharedModule } from './shared/shared.module';
import { createTranslateLoader } from './translateHttpFactory';

// We need a factory, since localStorage is not available during AOT build time.
export function storageFactory() : OAuthStorage {
  return localStorage
}

export function whichRepoBackend(): any {
  return WebBackend;
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
    SharedModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true
      } 
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    DataModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatAutocompleteModule,
    
  ],
  providers: [
    CookieConsentService,
    { provide: OAuthStorage, useFactory: storageFactory, },
    { provide: Backend, useClass: whichRepoBackend() },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export class OAuthNoopResourceServerErrorHandler implements OAuthResourceServerErrorHandler {

  handleError(err: HttpResponse<any>): Observable<any> {
      return throwError(err);
  }

}
