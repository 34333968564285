import { Mapper } from "src/app/core/base/mapper";
import { TimeWindowModel } from "src/app/core/models/time-window.model";
import { UiTimeWindowModel } from "../models/ui-time-window.model";

export class UiTimeWindowMapper extends Mapper<UiTimeWindowModel, TimeWindowModel>{
    mapFrom(param: UiTimeWindowModel): TimeWindowModel{
        let tw = {...param as any};
        if (param.startTs && param.startTs instanceof Date){
            tw.startTs = param.startTs.toISOString();
        }
        if (param.endTs && param.endTs instanceof Date){
            tw.endTs = param.endTs.toISOString();
        }
        return tw as TimeWindowModel;
    }
    
    mapTo(param: TimeWindowModel): UiTimeWindowModel{
        let tw = {...param as any};
        if (param.startTs){
            tw.startTs = new Date(param.startTs);
        }
        if (param.endTs){
            tw.endTs = new Date(param.endTs);
        }
        return tw as UiTimeWindowModel;
    }
}
