import { Mapper } from "src/app/core/base/mapper";
import { KeyHolderModel } from "src/app/core/models/key-holder.model";
import { UiKeyHolderModel } from "../models/ui-key-holder.model";

export class UiKeyHolderMapper extends Mapper<UiKeyHolderModel, KeyHolderModel>{
    mapFrom(param: UiKeyHolderModel): KeyHolderModel {
        return param as KeyHolderModel;
    }
    mapTo(param: KeyHolderModel): UiKeyHolderModel {
        return param as UiKeyHolderModel;
    }
}
