import { Mapper } from 'src/app/core/base/mapper';
import { GroupTreeNodeModel } from 'src/app/core/models/group-tree-node.model';
import { UiGroupTreeNodeModel } from '../models/ui-group-tree-node.model';

export class UiNodeMapper extends Mapper<UiGroupTreeNodeModel, GroupTreeNodeModel>{
    mapFrom(param: UiGroupTreeNodeModel): GroupTreeNodeModel {
        let childArray: GroupTreeNodeModel[] = [];
        param.children.forEach((child) => {
            childArray.push(this.mapFrom(child))
        });
        return {
            id: param.id,
            name: param.name,
            parentId: param.parentId,
            frozen: false,
            publicId: "",
            attachedLockId: param.attachedLockId,
            generic: {},
            children: childArray,
            permissions: param.permissions,
        }
    }

    mapTo(param: GroupTreeNodeModel): UiGroupTreeNodeModel {
        let childArray: UiGroupTreeNodeModel[] = [];
        param.children.forEach((child) => {
            childArray.push(this.mapTo(child))
        });
        return {
            expandable: !!param.children && param.children.length > 0,
            name: param.name,
            description: param?.description,
            level: 0,
            id: param.id,
            parentId: param.parentId,
            attachedLockId: param.attachedLockId,
            children: childArray,
            permissions: param.permissions,
        }
    }
}
