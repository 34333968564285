<div class="main-body">
  <h1>Error 404</h1>
  <p>The page you are looking for is not found.</p>
  <button mat-raised-button color="primary" class="back-button" (click)="goBackHome()" fill="outline" expand="block">
    <mat-icon>home</mat-icon>
    <span>Go Back Home</span>
  </button>
</div> 


