import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-delete-dialog',
    templateUrl: 'confirm-delete.dialog.html',
})
export class ConfirmDeleteDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public target: string,
    ) {
    }
}
