// declare const process: any;

import { UiImagePngModel } from "./app/presentation/models/ui-image-png.model";

export const appTitle = 'COMYDO';
//export const production = environment.production;
//export const googleAnalyticsId = 'UA-XXXXXXXX-Y';
//export const googleAnalyticsEnabled = false;
export const minPasswordLength = 5;
export const maxPasswordLength = 50;
//export const minEventSecretLength = 3;
// export const buildInformation = {
//   commit: typeof process !== 'undefined' && process.env.COMMIT_REF,
//   branch: typeof process !== 'undefined' && process.env.HEAD,
// };
// Auto-logout time
export const logoutTimeout = 30 * 60 * 1000; // 30 min
export const enableAutoLogOut = false;
export const DEFAULT_PROFILE_IMAGE: UiImagePngModel = {
    image: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAYAAADFw8lbAAABZklEQVRYhe3Vv2rCUByGYe/KnAjBTEmWuNb2DhRHDbkFEUQEwVQbaRdRFByEiAEVBBEHhxbaoZ1aaUXbuNj161Cy9A8YiJ4EzvDuDxx+34lsd58IQxHaAAZlUAZlUAb9brZYIqvpkBUFPM9DVhRkNR2zxTI4UKPeAMdxiEajv+I4Dka9QR/a7vT+BP6s3enRg67WDiRJPggqSTJWa4cOtG/ZByHd+pZNB2rUTE9Qo2bSgVYvrzxBq7SgoXn60BzTdheSeXILxeC7heILPUUMGijo8+s7rOEI+UIRqXQGqppAXBRBCEFcFKGqCaTSGeQLRVjDEV7ePk4H3Th7DOwxcpoOQRA8Db4gCMhpOgb2GBtnfzzoZDrHWfLcE+6/kskLTKZz/6HNVheEEF+QboQQNFtd/6C394/ged5XpFssFsPdw5M/0Jp5fRSkW9288QdaKleOCi2VKwwaTGgQYlAGDXoMyqBB7wsqvMoy9zVGsgAAAABJRU5ErkJggg==",
}
export const TRACKING_KEY_VALIDITY = 90; //in days

export const sideBarMinWidth = 300; //in px

export namespace theme {
    export const availableThemes = [
        //'automatic',
        'default'
    ];
}

// export namespace locale {
//     export const dateFormat = 'MM/DD/YYYY';
//     export const timePickerFormat = 'hh:mm A';
//     export const ISO8601Format = 'YYYY-MM-DD';
//     export const flatpickrDateFormat = 'm/d/Y';
// }
