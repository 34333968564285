<div class="layout" [ngSwitch]="currentSubpage">

  <ng-container *ngSwitchCase="SubpageType.Roles">
    <app-data-table mat-table [dataSource]="this.dataSource!" matSort [editableFields]="false"
      [columns]="['name', 'email', 'role']" (addRow)="setSubpageType(SubpageType.AddUser)">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let user"> {{user.firstName + " " +
          user.lastName}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let user"> {{user.email}} </td>
      </ng-container>
      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
        <td mat-cell *matCellDef="let user">
          <div id="roles-list" *ngFor="let role of user.roles; let i = index">
            <mat-chip-listbox #chiplist>
              <!-- current group roles-->
              <mat-chip-option id="shown-role" position="floating" 
                *ngIf="role.groupId === this.selectedGroupId"
                (removed)="removeRole(role)">
                {{ this.getRolebyId(role.roleId)?.roleDescription }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-option>
              <!-- these should be the inhereted ones -->
              <mat-chip-option id="shown-role" position="floating" 
                *ngIf="role.groupId !== this.selectedGroupId"
                (removed)="changeGroup(role.groupId)">
                {{ this.getRolebyId(role.roleId)?.roleDescription }}
                <button matChipRemove>
                  <mat-icon>north_east</mat-icon>
                </button>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: ['name', 'email', 'role'];"
        [class.expanded-row]="this.selectedUser === row"
        (click)="this.selectedUser = this.selectedUser === row ? null : row">
      </tr>
    </app-data-table>
  </ng-container>

  <ng-container *ngSwitchCase="SubpageType.AddUser">

    <form>
      <span mat-headline-5 class="mat-h1">{{ "Assign Role" | translate}}</span>
      <mat-form-field appearance="outline">
        <mat-label>E-Mail</mat-label>
        <input matInput 
          type="text" 
          aria-label="user" 
          name="user" 
          placeholder="please enter email"
          [formControl]="this.newRoleCtrl" 
          [matAutocomplete]="auto"
        >
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option 
            *ngFor="let us of filterdUserStrings | async" 
            [value]="us"
          >
            {{us}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ "Select a new role" | translate}} </mat-label>
        <mat-select name="role" [(value)]="newRole">
          @for (role of roles; track role){
            <ng-template [ngIf]="role.isInvitable">
              <mat-option [value]="role">
                {{role.roleDescription}}
              </mat-option>
            </ng-template>
          }
        </mat-select>
      </mat-form-field>
      <div class="filler"></div>
      <div class="form-row">
        <button mat-flat-button 
          (click)="currentSubpage=SubpageType.Roles" 
          translate="cancel">
        </button>
        <button mat-flat-button id="AddUserButton" 
          [disabled]="!this.newRoleCtrl.valid || this.newRole === undefined"
          color="primary"
          (click)="createRole(this.newRoleCtrl.value, this?.newRole!.id)">
          {{ "Assign Role" | translate }}
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngSwitchDefault>
    Default
  </ng-container>

</div>