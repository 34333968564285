import { Mapper } from 'src/app/core/base/mapper';
import { GroupModel } from 'src/app/core/models/group.model';
import { WebGroupModel } from '../models/web-group.model';

export class WebGroupMapper extends Mapper<WebGroupModel, GroupModel>{
    mapFrom(param: WebGroupModel): GroupModel{
        return {
                id: param.id,
                name: param.name,
                description: param.description,
                frozen: param.frozen,
                parentId: param.parentId,
                publicId: param.publicId,
                attachedLockId: param.attachedLockId ? param.attachedLockId : undefined,
                generic: param.generic,
                address: param.address ? param.address : undefined,
                permissions: param.permissions,
            }
    }

    mapTo(param: GroupModel): WebGroupModel{
        return {
                id: param.id,
                name: param.name,
                description: param.description,
                frozen: param.frozen,
                parentId: param.parentId,
                publicId: param.publicId,
                attachedLockId: param.attachedLockId ? param.attachedLockId : undefined,
                generic: param.generic,
                address: param.address ? param.address : null,
        }
    }
}
