import { Observable } from 'rxjs';
import { InvitationModel } from 'src/app/core/models/invitation.model';
import { SignupModel } from 'src/app/core/models/signup.model';
import { Credentials } from '../models/credentials.model';
import { CustomerModel } from '../models/customer.model';
import { DeviceInfoModel } from '../models/device.models';
import { GroupTreeModel } from '../models/group-tree.models';
import { GroupModel } from '../models/group.model';
import { ImagePngModel } from '../models/image-png.model';
import { KeyHolderModel } from '../models/key-holder.model';
import { KeyShareMailModel } from '../models/key-share-model';
import { KeyModel } from '../models/key.model';
import { KeysOfGroupModel } from '../models/keys-of-group.model';
import { PasswordChangeModel } from '../models/passwort-change.model';
import { RoleModel } from '../models/role.model';
import { UserModel } from '../models/user.model';
import { Uuid } from '../types/Uuid';
import { UserRoleModel } from '../models/user-role.model';

// mostly CRUD
export abstract class Backend {
  // Auth
  abstract loginUser(credentials: Credentials): Observable<boolean>;
  abstract logoutUser(): Observable<boolean>;
  abstract isAuthenticated(): Observable<boolean>;
  /**
   *  requests a password change email, which will be sent by the back end 
   */
  abstract requestNewPassword(email: string): Observable<void>;
  /**
   *  reset the password
   */
  abstract resetPassword(newPw: PasswordChangeModel): Observable<void>;

  // User
  /**
   *  returns all users the client has access to
   */
  abstract getAllUsers(): Observable<UserModel[]>;
  /**
   *  returns the clients user
   */
  abstract getCurrentUser(): Observable<UserModel>;
  /**
   *  returns the user for the given id
   */
  abstract getUserById(id: Uuid): Observable<UserModel>;
  /**
   *  creates a new user and returns it with the assigned id
   */
  abstract createUser(user: UserModel): Observable<UserModel>;
  /**
   *  changes an existing user
   */
  abstract updateUser(user: UserModel): Observable<void>;
  /**
   * 
   *  changes the currently logged in user
   */
  abstract updateCurrentUser(user: UserModel): Observable<void>;
  /**
   *  deletes an existing user
   */
  abstract deleteUser(id: Uuid): Observable<void>;

  //Group
  /**
   *  returns all groups the client has access to
   */
  abstract getAllGroups(): Observable<GroupModel[]>;
  /**
   *  creats a new group and returns it with the assigned id
   */
  abstract createGroup(group: GroupModel): Observable<GroupModel>;
  /**
   *  returns the group for the given id
   */
  abstract getGroupById(id: Uuid): Observable<GroupModel>;
  /**
   *  updates an existing group
   */
  abstract updateGroup(group: GroupModel): Observable<void>;
  /**
   *  deletes an existing group
   */
  abstract deleteGroup(group: GroupModel): Observable<void>;
  /**
   *  returns all accessable groups in (multiple) tree structure(s)
   */
  abstract getGroupTrees(): Observable<GroupTreeModel[]>;
  /**
   *  returns all accessable users assigned to the specified group
   */
  abstract getAllUsersForGroup(group: GroupModel): Observable<UserModel[]>;
  /**
   * returns the validity of the given key in the group
   */
  abstract getKeyExpirationStatus(params: [groupId: Uuid, keyId: Uuid]): Observable<KeyStateModel>;
  /**
   *  opens an existing Lock (a special type of Group)
   */
  abstract openLock(group: GroupModel): Observable<void>;

  //Device
  /**
   * 
   * returns a list of device info objects 
   */
  abstract getDeviceInfoList(): Observable<DeviceInfoModel[]>;

  //Key
  /**
   *  returns the key for the given id
   */
  abstract getKeyById(id: Uuid): Observable<KeyModel>;
  /**
   *  returns all accessable keys assigned to the specified group
   */
  abstract getAllKeysForGroup(group: GroupModel): Observable<KeysOfGroupModel>;
  /**
   *  returns all unasigned keys, i.e. keys without key rights, for given customer
   */
  abstract getAllUnasignedKeys(customerId: Uuid): Observable<KeyModel[]>;
  /**
   *  creats a new key and returns it with the assigned id
   */
  abstract createKey(key: KeyModel): Observable<KeyModel>;
  /**
   * deletes an existing key
   */
  abstract deleteKey(key: KeyModel): Observable<void>;
  /**
   *  updates an existing key
   */
  abstract updateKey(key: KeyModel): Observable<void>;
  /**
   *  share key with email of given KeyHolder
   */
  abstract shareKeyMail(params: KeyShareMailModel): Observable<void>;
  /*
   *  get the QR code for the given key from the BE
   */
  abstract getKeyQrCode(params: KeyModel): Observable<ImagePngModel>;

  // Customer
  // abstract getAllCustomers(): Observable<CustomerModel[]>;
  // abstract getCustomerById(id: Uuid): Observable<CustomerModel>;
  abstract getCustomerIdByGroupId(groupId: Uuid): Observable<CustomerModel>;

  /**
   * returns all keyHolder for given customerId
   */
  abstract getKeyholderForCustomerId(customerId: Uuid): Observable<KeyHolderModel[]>;
  /**
   * returns all accessable key holders
   */
  abstract getAllKeyholders(): Observable<KeyHolderModel[]>;
  /**
   *  return the key holder for the given id
   */
  abstract getKeyholderById(id: Uuid): Observable<KeyHolderModel>;
  /**
   *  deletes an existing key right
   */
  abstract deleteKeyRight(id: Uuid): Observable<void>;
  /**
   *  creates a new key holder for a given customer and returns it with the assigned id
   */
  abstract createKeyHolder(keyholder: KeyHolderModel): Observable<KeyHolderModel>;
  /**
   * update the given key holder
   */
  abstract updateKeyHolder(keyholder: KeyHolderModel): Observable<KeyHolderModel>;
  /**
   * deletes the key holder with the given UUID
   */
  abstract deleteKeyHolder(keyHolderId: Uuid): Observable<void>;

  //Role
  /**
   * returns all accessable roles to a client
   */
  abstract getAllRoles(): Observable<RoleModel[]>;
  /**
   * return all roles for the given group
   */
  abstract getAllUserRoles(): Observable<UserRoleModel[]>;
  /**
   * updates an existing role
   */
  abstract updateUserRole(role: UserRoleModel): Observable<void>;
  /**
   * delete an existing role
  */
  abstract deleteUserRole(uuid: Uuid): Observable<void>;
  /**
  * create a new role for a user within a group
  */
  abstract createUserRole(role: UserRoleModel): Observable<UserRoleModel>;

  // Invitations / Sign up
  /**
   * send new invite to create a user
   */
  abstract createUserInvitation(invite: InvitationModel): Observable<InvitationModel>;
  /**
   * get all sent invitations
   */
  abstract getAllInvitations(): Observable<InvitationModel[]>;
  /**
   * re-send invite to an already invited email address
   */
  abstract resendInvitation(id: Uuid): Observable<void>;
  /**
   * create user with filled in form info
   */
  abstract signupUser(signupUser: SignupModel): Observable<void>;
}
