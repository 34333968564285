import { Mapper } from 'src/app/core/base/mapper';
import { UiInvitationModel } from '../models/ui-invitation.model';
import { InvitationModel } from 'src/app/core/models/invitation.model';


export class UiInvitationMapper extends Mapper<UiInvitationModel, InvitationModel>{
    mapFrom(param: UiInvitationModel): InvitationModel {
        return param as InvitationModel
    }

    mapTo(param: InvitationModel): UiInvitationModel {
        return param as UiInvitationModel
    }
}
