import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterContentInit,
  Component,
  ContentChildren, EventEmitter, Input, Output, QueryList,
  ViewChild
} from '@angular/core';
import {
  MatColumnDef,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table';

/**
 * Table component that accepts column and row definitions in its content to be 
 * registered to the table.
 */
@Component({
  selector: 'app-data-table',
  templateUrl: 'data-table.component.html',
  styleUrls: ['data-table.scss'],
})
export class DataTableComponent<T> implements AfterContentInit {
  @ContentChildren(MatHeaderRowDef) headerRowDefs!: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs!: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs!: QueryList<MatColumnDef>;

  @ViewChild(MatNoDataRow) noDataRow!: MatNoDataRow;
  @ViewChild(MatTable, { static: true }) table!: MatTable<T>;

  @Input() editableFields!: boolean;
  @Input() addButtonEnabled: boolean = true;
  @Input() editButtonEnabled: boolean = true;
  @Input() deleteButtonEnabled: boolean = true;
  @Input() columns!: string[];
  @Input() dataSource: MatTableDataSource<T> = new MatTableDataSource();
  @Input() editCond: (row: T) => boolean = () => true;

  @Output() addRow = new EventEmitter()
  @Output() deleteRows = new EventEmitter<Array<T>>()
  @Output() editRow = new EventEmitter<T>()

  selection = new SelectionModel<T>(true, []);

  get isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  get allColumns() {
    return ['select', ...this.columns]
  }

  masterToggle() {
    if (this.isAllSelected) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.filteredData);
  }

  canEdit() {
    return this.selection.selected.length == 1 && 
      this.editCond(this.selection.selected[0])
  }

  ngAfterContentInit() {
    this.columnDefs.forEach(
      columnDef => this.table.addColumnDef(columnDef)
    );
    this.rowDefs.forEach(
      rowDef => this.table.addRowDef(rowDef)
    );
    this.headerRowDefs.forEach(
      headerRowDef => this.table.addHeaderRowDef(headerRowDef)
    );
    this.table.setNoDataRow(this.noDataRow);
  }
}
