import { Injectable } from '@angular/core';
import { Backend } from '../base/backend';
import { UseCase } from '../base/use-case'
import { UserModel } from '../models/user.model';
import { Observable } from 'rxjs';
import { Uuid } from '../types/Uuid';
import { GroupModel } from '../models/group.model';
import { SignupModel } from '../models/signup.model';

@Injectable({
  providedIn: 'root'
})
export class GetAllUsersUsecase implements UseCase<void, Array<UserModel>> {
  constructor(
    private userRepository: Backend,
    ) {}

  execute(params: void): Observable<Array<UserModel>> {
    return this.userRepository.getAllUsers();
  }
}

@Injectable({
  providedIn: 'root'
})
export class GetCurrentUserUsecase implements UseCase<void, UserModel> {
  constructor(
    private userRepository: Backend,
  ) {}

  execute(params: void): Observable<UserModel> {
    return this.userRepository.getCurrentUser();
  }
}

@Injectable({
  providedIn: 'root'
})
export class GetUserByIdUsecase implements UseCase<Uuid, UserModel> {
  constructor(
    private userRepository: Backend
  ) { }

  execute(params: Uuid): Observable<UserModel> {
    return this.userRepository.getUserById(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class CreateUserUsecase implements UseCase<UserModel, UserModel> {
  constructor(
    private userRepository: Backend,
    ) { }

  execute(params: UserModel): Observable<UserModel> {
    return this.userRepository.createUser(params);
  }
}


@Injectable({
  providedIn: 'root'
})
export class UpdateUserUsecase implements UseCase<UserModel, void > {
  constructor(
    private userRepository: Backend,
    ) { }

  execute(params: UserModel): Observable<void> {
    return this.userRepository.updateUser(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UpdateCurrentUserUsecase implements UseCase<UserModel, void > {
  constructor(
    private userRepository: Backend,
    ) { }

  execute(params: UserModel): Observable<void> {
    return this.userRepository.updateCurrentUser(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeleteUserUsecase implements UseCase<Uuid, void> {
  constructor(private userRepository: Backend) { }

  execute(params: Uuid): Observable<void> {
    return this.userRepository.deleteUser(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class GetAllUsersForGroupUsecase implements UseCase<GroupModel, Array<UserModel>> {

  constructor(private userRepository: Backend) { }

  execute(params: GroupModel): Observable<Array<UserModel>> {
    return this.userRepository.getAllUsersForGroup(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class SignupUserUsecase implements UseCase<SignupModel, void> {

  constructor(private userRepository: Backend) { }

  execute(params: SignupModel): Observable<void> {
    return this.userRepository.signupUser(params);
  }
}
