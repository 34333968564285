import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Credentials } from 'src/app/core/models/credentials.model';
import { IsAuthenticatedUsecase, LoginUserUsecase } from 'src/app/core/useCases/credentials.usecases';
import { maxPasswordLength, minPasswordLength } from 'src/global';

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  user: any = {};
  message: string = "";
  loginForm: FormGroup;
  hidePW = true;

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private isAuthenticated: IsAuthenticatedUsecase,
    private loginUser: LoginUserUsecase,
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(minPasswordLength),
        Validators.maxLength(maxPasswordLength)],
      )],
    });
  }

  submitForm() {
    this.login();
  }

  ngOnInit() {
    this.isAuthenticated.execute().subscribe(loggedIn => {
      if (loggedIn) {
        this.router.navigateByUrl('/home');
      }
    });
  }

  login() {
    const data = this.loginForm.value;
    let credentials: Credentials = {
      email: data['email'],
      password: data['password']
    };

    this.loginUser.execute(credentials).subscribe(loggedIn => {
      if (loggedIn) {
        this.router.navigateByUrl('/home');
      }
      else {
        this.loginForm.patchValue({
          password: ""
        })
        this.present_snack_msg("Could not log in with those credentials.");
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  present_snack_msg(msg: string) {
    this._snackBar.open(msg, "OK", {
      duration: 5000,
      verticalPosition: 'top',
    });
  }

}
