import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule } from '@angular/router';
import { routes } from 'src/app/routes';
import { HomePageModule } from './presentation/pages/home/home.module';


export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always'
};

@NgModule({
  imports: [
    HomePageModule,

    RouterModule.forRoot(routes, routingConfiguration)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
