import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PageLocationService } from '../services/page-location.service';
import { createTranslateLoader } from '../translateHttpFactory';
import { VarDirective } from './ng-var.directive';
import { ResolveGroupName as ResolveGroupNamePipe } from 'src/app/presentation/pipes/resolve-group-name.pipe'
import { ResolveKeyHolderInfoStringPipe } from '../presentation/pipes/resolve-keyHolder-info.pipe';
import { UserToUIUserPipe } from '../presentation/pipes/user-to-UiUser.pipe';


@NgModule({
  declarations: [
    VarDirective,
    ResolveGroupNamePipe,
    ResolveKeyHolderInfoStringPipe,
    UserToUIUserPipe
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
  ],
  exports: [
    // any pipe, directive or component you want to share
    HttpClientModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    VarDirective,
    ResolveGroupNamePipe,
    ResolveKeyHolderInfoStringPipe,
    UserToUIUserPipe
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        // any services you want to share
        PageLocationService,
      ],
    };
  }
}
