import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { cloneDeep } from "src/app/shared/util";
import { GroupTreeNodeModel } from "../models/group-tree-node.model";
import { GroupTreeModel } from "../models/group-tree.models";
import { GroupModel } from "../models/group.model";
import { Uuid } from "../types/Uuid";
import { GetGroupTreeUsecase } from "../useCases/group.usecases";

@Injectable({
    providedIn: 'root'
})
export class GroupTreeService {
    breadCrumpGroups: GroupModel[] = [];

    public currentGroupTrees$ 
        = new BehaviorSubject<GroupTreeModel[] | undefined>(undefined);
    //a list of all groups, with the names containing the full branch
    public currentGroupBreadCrumpList$
        = new BehaviorSubject<GroupModel[] | undefined>(undefined);

    constructor(
        private getGroupTree: GetGroupTreeUsecase
    ){}

    public loadGroupTree(){
        this.breadCrumpGroups = [];
        this.getGroupTree.execute().subscribe(gt => {
            this.currentGroupTrees$.next(gt);
            let gtc = cloneDeep(gt);
            this.constructGroupBreadCrumpList(gtc);
            this.currentGroupBreadCrumpList$.next(this.breadCrumpGroups);
        })
    }

    constructGroupBreadCrumpList(gts: GroupTreeModel[]){
        gts.forEach(tree => {
            this.breadCrumpGroups.push(tree.root)
            if (tree.root.children){
                tree.root.children.forEach(child => {
                    child.name = tree.root.name + " > " + child.name;
                })
                this.addChildrenRecursion(tree.root.children);
            }
        })
    }

    addChildrenRecursion(children: GroupTreeNodeModel[]){
        children.forEach(child => {
            child.children.forEach(grandChild => {
                grandChild.name = child.name + " > " + grandChild.name;
            }) 
            if (child.children){
                this.addChildrenRecursion(child.children)
            }
            this.breadCrumpGroups.push(child);
        })
    }
}
