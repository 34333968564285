import { Mapper } from 'src/app/core/base/mapper';
import { WebUserRoleModel } from '../models/web-user-role.model';
import { UserRoleModel } from 'src/app/core/models/user-role.model';



export class WebUserRoleMapper extends Mapper<WebUserRoleModel, UserRoleModel>{
    mapFrom(param: WebUserRoleModel): UserRoleModel{
        return param as UserRoleModel
    }

    mapTo(param: UserRoleModel): WebUserRoleModel{
       return param as WebUserRoleModel
    }
}