import { HomePageComponent } from './home.page';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input'; 
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs'; 
import { ComponentsModule } from '../../components/components.module';
import { KeysPageModule } from '../keys/keys.module';
import { CustomerPageModule } from '../customer/customer.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule.forRoot(),
    RouterModule,
    MatDialogModule,
    MatSidenavModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatDividerModule,
    MatTabsModule,
    ComponentsModule,
    KeysPageModule,
    CustomerPageModule
  ],
  declarations: [
    HomePageComponent,
  ]
})
export class HomePageModule {}
