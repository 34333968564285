import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { GroupTreeModel } from 'src/app/core/models/group-tree.models';
import { GroupModel } from 'src/app/core/models/group.model';
import { GroupTreeService } from 'src/app/core/services/group-tree.service';
import { Uuid } from 'src/app/core/types/Uuid';
import { DeleteGroupUsecase } from 'src/app/core/useCases/group.usecases';
import { ConfirmDialogComponent } from '../../dialogs/confirm.dialog';
import { CreateGroupDialogComponent } from '../../dialogs/create-group.dialog';
import { UiGroupMapper } from '../../mappers/ui-group.mapper';
import { UiNodeMapper } from '../../mappers/ui-groupTree.mapper';
import { UiGroupTreeNodeModel } from '../../models/ui-group-tree-node.model';
import { UiGroupTreeModel } from '../../models/ui-group-tree.model';
import { NotificationService } from '../../services/notification.service';

interface SubPage{
  path: string;
}

const SUB_PAGES = [
  {path: "groupdetails"},
  {path: "keys"},
  {path: "roles"},
]

@Component({
  selector: 'app-group-tree',
  templateUrl: './group-tree.component.html',
  styleUrls: ['./group-tree.component.scss']
})
export class GroupTreeComponent implements OnInit {
  selectedGroupId!: Uuid;
  
  readonly defaultSubPage = SUB_PAGES[0];
  currentSubPage: SubPage;

  editEnabled: boolean = true;
  groupUITree?: UiGroupTreeNodeModel;
  uiNodeMapper: UiNodeMapper = new UiNodeMapper();
  groupMapper = new UiGroupMapper();
  uiTrees?: UiGroupTreeModel[]; //contains what is shown
  groupTrees: GroupTreeModel[] | undefined = []; //temp storage before mapping
  //dataSources: any[] = [];
  //treeControls: FlatTreeControl<TreeUINode>[] = [];
  //customerName: string[] = [];

  hasChild = (_: number, node: UiGroupTreeNodeModel) => node.expandable;

  

  constructor(
    private deleteGroup: DeleteGroupUsecase,
    private groupTreeService: GroupTreeService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private translation: TranslateService
  ) 
  { this.currentSubPage = this.defaultSubPage}

  private _transformer = (node: UiGroupTreeNodeModel, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      id: node.id,
      level: level,
      parentId: node.parentId,
      permissions: node.permissions,
    } as UiGroupTreeNodeModel;
  }

  // I use one treeFlattener for multiple trees... 
  // this seems to work, but might cause unexpected problems
  treeFlattener = new MatTreeFlattener<UiGroupTreeNodeModel, UiGroupTreeNodeModel>(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  ngOnInit(): void {
    this.groupTreeService.loadGroupTree()
    this.route.params.subscribe(
      (p: { [x: string]: Uuid; }): void => {
        this.setGroupId(p["groupid"]);
      });
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.updateSubPage();
      }
    })
    this.groupTreeService.currentGroupTrees$.subscribe(
      trees => {
        if (trees) {
          this.uiTrees = [];
          for (let tree of trees) {
            let tc = new FlatTreeControl<UiGroupTreeNodeModel>(
              node => node.level,
              node => node.expandable
            );
            let ds = new MatTreeFlatDataSource(
              tc, this.treeFlattener);
            ds.data = [this.uiNodeMapper.mapTo(tree.root)];
            let uit: UiGroupTreeModel = {
              dataSource: ds,
              treeControl: tc,
              customerName: tree.customer?.name,
              customerId: tree.customer?.id,
            };
            this.uiTrees.push(uit);
          }
          this.expandTrees();
        }
      },
      error => {
        this.notificationService.notifyUserOfHttpError(error);
      },
      //Observable completed
      () => {

      }
    );
  }

  setGroupId(groupId: Uuid) {
    this.selectedGroupId = groupId;
  }

  updateSubPage() {
    for (let page of SUB_PAGES){
      if ( this.router.url.endsWith(page.path) ){
        this.currentSubPage = page;
        return;
      }
    }
    this.currentSubPage = this.defaultSubPage;
  }


  editButtonClick() {
    this.expandTrees();
    this.editEnabled = !this.editEnabled;
  }

  expandTrees() {
    if (this.uiTrees) {
      for (let uit of this.uiTrees) {
        uit.treeControl.expandAll();
      }
    }
  }

  collapseTrees() {
    if (this.uiTrees) {
      for (let uit of this.uiTrees) {
        uit.treeControl.collapseAll();
      }
    }
  }

  removeGroup(group: GroupModel) {
    this.router.navigateByUrl('/home/'+ group.parentId + this.currentSubPage);
    this.deleteGroup.execute(group).subscribe(
      _result => { },
      error => { this.notificationService.notifyUserOfHttpError(error.error) },
      () => { this.groupTreeService.loadGroupTree(); }
    );
  }

  openCreateGroupDialog(parent: GroupModel) {
    this.dialog.open(CreateGroupDialogComponent, {
      width: "300px",
      data: {
        parent
      },
    })
  };

  openDeleteGroupDialog(group: GroupModel) {
    let confirm_ref = this.dialog.open(ConfirmDialogComponent, {
      data: {
        header: this.translation.instant('Please confirm'),
        message: this.translation.instant('groupDeletionConfirmation', { 'groupName': group.name }),
        no_action_button: this.translation.instant('Cancel'),
        confirm_action_button: this.translation.instant('deleteLabel')
      },
    });

    confirm_ref.afterClosed()
      .pipe(filter(r => r))
      .subscribe(() => this.deleteGroup.execute(group).subscribe(() => {
        this.groupTreeService.loadGroupTree();
        this.notificationService.notify(this.translation.instant('groupDeleted'));
        this.router.navigateByUrl('/home/group/' + group.parentId + '/' + this.currentSubPage.path);
      }))
  }
}
