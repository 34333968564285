import { Pipe, PipeTransform } from '@angular/core';
import { Uuid } from 'src/app/core/types/Uuid';
import { UiGroupModel } from '../models/ui-group.model';

@Pipe({ name: 'resolveGroupName' })
export class ResolveGroupName implements PipeTransform {
    transform(id: Uuid, groups: Map<Uuid, UiGroupModel>): string {
        return groups.get(id)?.name ?? id as string
    }
}
