import { Injectable } from "@angular/core";
import { UseCase } from "../base/use-case";
import { KeyHolderModel } from "../models/key-holder.model";
import { Backend } from "../base/backend";
import { Observable } from "rxjs";
import { Uuid } from "../types/Uuid";


@Injectable({
    providedIn: 'root'
  })
  export class GetKeyHolderByIdUsecase implements UseCase<Uuid, KeyHolderModel> {
  
    constructor(private userRepository: Backend) { }
  
    execute(params: Uuid): Observable<KeyHolderModel> {
      return this.userRepository.getKeyholderById(params);
    }
}

@Injectable({
  providedIn: 'root'
})
export class GetAllKeyHolderForCustomerIdUsecase implements UseCase<Uuid, KeyHolderModel[]>{
  constructor(private userRepository: Backend) { }
  
  execute(params: Uuid): Observable<KeyHolderModel[]> {
    return this.userRepository.getKeyholderForCustomerId(params);
  }
}
  
@Injectable({
  providedIn: 'root'
})
export class GetAllKeyHoldersUsecase implements UseCase<void, Array<KeyHolderModel>> {

  constructor(private userRepository: Backend) { }

  execute(): Observable<Array<KeyHolderModel>> {
    return this.userRepository.getAllKeyholders();
  }
}

@Injectable({
  providedIn: 'root'
})
export class CreateKeyHolderUsecase implements UseCase<KeyHolderModel, KeyHolderModel> {

  constructor(private userRepository: Backend) { }

  execute(params: KeyHolderModel): Observable<KeyHolderModel> {
    return this.userRepository.createKeyHolder(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UpdateKeyHolderUsecase implements UseCase<KeyHolderModel, KeyHolderModel> {

  constructor(private userRepository: Backend) { }

  execute(params: KeyHolderModel): Observable<KeyHolderModel> {
    return this.userRepository.updateKeyHolder(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeleteKeyHolderUsecase implements UseCase<Uuid, void> {

  constructor(private userRepository: Backend) { }

  execute(params: Uuid): Observable<void> {
    return this.userRepository.deleteKeyHolder(params);
  }
}