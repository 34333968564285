<h1 mat-dialog-title>{{confirm_data.header | translate}}</h1>
<div mat-dialog-content>{{confirm_data.message | translate}} </div>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">
        {{confirm_data.no_action_button | translate }}
    </button>
    <button mat-button color="warn" [mat-dialog-close]="true">
        {{confirm_data.confirm_action_button | translate }}
    </button>
</div>
