import { Mapper } from "src/app/core/base/mapper";
import { GroupModel } from "src/app/core/models/group.model";
import { UiGroupModel } from "../models/ui-group.model";

export class UiGroupMapper extends Mapper<UiGroupModel, GroupModel>{
    mapFrom(param: UiGroupModel): GroupModel {
        return param as GroupModel;
    }
    mapTo(param: GroupModel): UiGroupModel {
        let uig = param as UiGroupModel;
        //uig.level = 2;
        return uig;
    }
}
