<h1 mat-dialog-title>{{"New Group" | translate}}</h1>
<form [formGroup]="newGroupForm">
  <div mat-dialog-content>

    <p>
      <mat-label>{{"Parent Group:" | translate}} {{data.parent.name}}
      </mat-label>
    </p>
    <p>

      <mat-form-field>
        <mat-label>{{"Group name" | translate}}</mat-label>
        <input matInput formControlName="name">
      </mat-form-field>
    </p>
    <p>

      <mat-form-field>
        <mat-label>{{"Group description" | translate}}</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>
    </p>
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="false">
      {{"Cancel" | translate}}
    </button>
    <button mat-button color="primary" 
      [mat-dialog-close]="true" 
      type="button"
      (click)="createGroup()"
      [disabled]="this.newGroupForm.invalid"
    >
      {{"Create New Group" | translate }}
    </button>
  </div>
</form>
