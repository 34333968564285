import { Injectable } from '@angular/core';
import { PageRoute, routes } from '../routes';

@Injectable({
  providedIn: 'root'
})
export class PageLocationService {

  constructor() {}



  getAllPages(onlyShownInMenu = true): PageRoute[] {
    // Native sortBy implementation to replace lodash
    const sortBy = (key:any) => {
      return (a:any, b:any) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
    };

    let pages: PageRoute[] = routes.concat().sort( sortBy("index") );

    if (onlyShownInMenu) {
      pages = pages.filter(r => r.shownInMenu);
    }
    return pages;
  }
}
