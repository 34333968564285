<div class="container" *ngVar="(userService.currentUser$ | async) as user">
  <div class="header">
    <mat-toolbar mat-tab-nav-bar color="primary">

      <!-- this is the alternative menu for small screens -->
      <div class="dropdown-menu">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngIf="userService.isUserLoggedIn" name="fontStyles">
            <li>
              <button mat-button routerLink="/home">
                {{'Home' | translate}}
              </button>
            </li>
            <li>
              <button mat-button routerLink="/admin" *ngIf="user.permissions.hasAdminRole === true">
                {{'Admin' | translate}}
              </button>
              <!-- <li>
              <button mat-button routerLink="/usermanagement">
                {{'User'|translate}}
              </button>
            </li> -->
          </ng-container>
        </mat-menu>
      </div>

      <img src="/assets/imgs/comydo_logo_cropped.png">

      <!-- this is the menu/toolbar for large screens -->
      <a mat-tab-link class="toolbar-buttons" name="fontStyle" aria-label="Font Style">
        <!-- <button mat-button (click)="openPage(p)"
          *ngIf="p.shownInMenu && (userService.isUserLoggedIn ? p.shownAfterLogin : p.shownBeforeLogin)">
          {{ (p.headerName || p.name) | translate }}
        </button> -->
        <ng-container *ngIf="userService.isUserLoggedIn">
          <button mat-button routerLink="/home">
            {{'Home' | translate}}
          </button>
          <button mat-button routerLink="/admin" *ngIf="user.permissions.hasAdminRole === true || 
                   user.permissions.isSuperuser === true">
            {{'Admin' | translate}}
          </button>
          <!-- <button mat-button routerLink="/usermanagement">
            {{'User'|translate}}
          </button> -->
        </ng-container>
      </a>
      <div class="filler"></div>
      <!-- this is the right dropdown -->
      <div *ngIf="userService.isUserLoggedIn" class="avatar-menu">
        <button mat-button class="avatar-button" routerLink="/profile">
          <img class="avatar-image" *ngIf="(user | userToUIUser).image; else placeholder"
            [src]="(user | userToUIUser).image?.image">
          <ng-template #placeholder>
            <img class="avatar-image" src="assets/imgs/user-icon.png">
          </ng-template>
          {{ this.user.firstName}} {{this.user.lastName }}
        </button>
        <button mat-icon-button color="warn" (click)="logout()">
          <mat-icon>logout</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  </div>

  <div class="content" role="main">
    <router-outlet></router-outlet>
  </div>
</div>