import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Backend } from "../base/backend";
import { UseCase } from "../base/use-case";
import { DeviceInfoModel } from "../models/device.models";

@Injectable({
    providedIn: 'root'
})
export class GetDeviceInfoListUsecase implements UseCase<void, DeviceInfoModel[]>{

    constructor(private userRepository: Backend) {}
    
    execute(params: void): Observable<DeviceInfoModel[]>{
        return this.userRepository.getDeviceInfoList();
    }

}
