<div mat-dialog-content>
  <mat-list-item>
    <mat-form-field position="floating" class="example-full-width" appearance="fill">
      <mat-label>Add an existing user</mat-label>
      <mat-select [disabled]="this.newInvite.email.length > 0" [(ngModel)]="this.newInvite.id">
        <mat-option *ngFor="let user of this.allUsers" [value]="user.id">{{ user.firstName+" "+user.lastName }}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list-item>
  <mat-list-item>
      <mat-form-field position="floating" class="example-full-width" appearance="fill">
        <mat-label>Or enter new email</mat-label>
        <input matInput [disabled]="!!this.newInvite.id" [(ngModel)]="this.newInvite.email">
      </mat-form-field>
    </mat-list-item>
    <mat-list-item>
      <mat-form-field appearance="fill">
        <mat-label>Select a new role</mat-label>
        <!-- <mat-select [(ngModel)]="this.newInvite.role">
            <mat-option id="admin" value="AD">
              {{ this.RoleType.AD }}
            </mat-option>
            <mat-option id="ta" value="TA">
              {{ this.RoleType.TA }}
            </mat-option>
            <mat-option id="te" value="US">
              {{ this.RoleType.US }}
            </mat-option>
        </mat-select> -->
      </mat-form-field>
    </mat-list-item>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button fill="clear" shape="round" (click)="closeDialog()">Cancel</button> 
  <button mat-stroked-button mat-dialog-close id="AddUserButton" 
    [disabled]="!( (this.newInvite.email.length > 0 || this.newInvite.id) && this.newInvite.group.length > 0  && this.newInvite.roleId.length > 0)" 
    (click)="inviteUserWithInputData()">
      Add To Group/Invite
  </button>
</div>
