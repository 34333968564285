import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.page.html',
  styleUrls: ['./reset.page.scss']
})
export class ResetPageComponent implements OnInit {
  keyParam!: string;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const key = this.route.snapshot.queryParamMap.get('key')!;
    this.keyParam = key;
  }

}
