import { Mapper } from "src/app/core/base/mapper";
import { KeyHolderModel } from "src/app/core/models/key-holder.model";
import { WebKeyHolder } from "../models/web-key-holder.model";

export class WebKeyHolderMapper extends Mapper<WebKeyHolder, KeyHolderModel>{
    mapFrom(param: WebKeyHolder): KeyHolderModel {
        return param as KeyHolderModel;
    }
    mapTo(param: KeyHolderModel): WebKeyHolder {
        delete param['permissions'];
        return param as WebKeyHolder;
    }
    
}
