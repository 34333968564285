<form [formGroup]="this.resetPwFg" (ngSubmit)="changePW()">
    <mat-form-field appearance="outline">
        <mat-label for="password">
            {{ 'New Password' | translate }}
        </mat-label>
        <input matInput type="password" formControlName="password">
        <mat-error *ngIf="resetPwFg.getError('required', 'password')" translate>required</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label for="passwordRepeat">
            {{ 'Repeat new Password' | translate }}
        </mat-label>
        <input matInput type="password" formControlName="passwordRepeat">
        <mat-error *ngIf="resetPwFg.getError('required', 'passwordRepeat')" translate>required</mat-error>
    </mat-form-field>
    <mat-error *ngIf="resetPwFg.getError('passwordsDontMatch')" translate>passwordsDontMatch</mat-error>
    <div class="filler"></div>
    <div class="form-row"><button mat-flat-button color="accent" [disabled]="resetPwFg.invalid" type="submit">Change Password</button></div>
</form>