import { Mapper } from "src/app/core/base/mapper";
import { ImagePngModel } from "src/app/core/models/image-png.model";

export class WebImagePngMapper extends Mapper<ArrayBuffer, ImagePngModel>{
    mapFrom(param: ArrayBuffer): ImagePngModel {
        return {
            image: param,
        }
    }

    mapTo(param: ImagePngModel): ArrayBuffer{
        return param.image;
    }
}
