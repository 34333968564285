import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


class ErrorDialogData {
    title!: string
    message!: string
}

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error.dialog.html',
    styleUrls: ['./error.dialog.scss']
})
export class ErrorDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: ErrorDialogData,
    ) {
    }
}
