import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {

  private consentStorageKey = 'cookie-consent';
  private forceClose = new BehaviorSubject<boolean>(false);
  private grantConsent = new BehaviorSubject<boolean>(false);

  public requesting = false;
  public forceClosed$ = this.forceClose.asObservable();
  public consentGranted$ = this.grantConsent.asObservable();
  
  constructor(public storage: LocalStorageService) {
  }

  async setConsent(consented = true) {
    await this.storage.set(this.consentStorageKey, String(consented) );
    this.grantConsent.next(consented);
  }

  async getConsent() {
    const consent = await this.storage.get(this.consentStorageKey);
    return consent === "true";
  }

  closeConsentBox() {
    this.forceClose.next(true);
  }
}
