// TODO: Display user's groups and roles in the respective groups in the profile-card

import { LogoutUserUsecase } from 'src/app/core/useCases/credentials.usecases';
import { DeleteUserUsecase, UpdateUserUsecase } from 'src/app/core/useCases/user.usecases';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UiUserMapper } from '../../mappers/ui-user.mapper';
import { DomSanitizer } from '@angular/platform-browser'
import { emptyUiUser, UiUserModel } from '../../models/ui-user.model';
import { UserModel } from "../../../core/models/user.model";
import { cloneDeep } from 'src/app/shared/util';
import { AlertDialogComponent } from '../../dialogs/alert.dialog';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete.dialog';
import { filter } from 'rxjs/operators';
import { concat } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePageComponent implements OnInit {
  dataHasChanged = false;
  imageError?: string;
  uiUserMapper = new UiUserMapper(this.sanitizer);


  public visibleUser: UiUserModel = emptyUiUser();
  public selectedLanguage: string = "";

  private backupUser: UiUserModel = emptyUiUser();


  constructor(
    public router: Router,
    private sanitizer: DomSanitizer,
    private updateUser: UpdateUserUsecase,
    private deleteUser: DeleteUserUsecase,
    private logoutUser: LogoutUserUsecase,
    private userService: UserService,
    private languageService: LanguageService,
    public dialog: MatDialog,
  ) { 
    languageService.getLanguage().subscribe(l => this.selectedLanguage = l.code);
  }



  ngOnInit() {
    this.userService.currentUser$.subscribe(value => value && this.loadUser(value));
  }

  loadUser(user: UserModel) {
    this.backupUser = this.uiUserMapper.mapTo(user);
    this.visibleUser = cloneDeep(this.backupUser);
  }

  userDataChanged() {
    this.dataHasChanged = true;
  }

  async cancelChanges() {
    if (this.dataHasChanged) {
      this.visibleUser = cloneDeep(this.backupUser);
    }
    this.languageService.getLanguage().subscribe(l => this.selectedLanguage = l.code);
    this.dataHasChanged = false;
  }

  async saveUserData() {
    if (this.dataHasChanged && this.visibleUser) {
      this.updateUser.execute(
        this.uiUserMapper.mapFrom(this.visibleUser)).subscribe({
          error: (error) => {
            console.error(error)
          },
          complete: () => this.userService.onUserUpdated()
        });
      this.languageService.saveLanguage(this.selectedLanguage)
    }
    this.dataHasChanged = false;
  }

  async confirmAccountDelete() {
    const ref = this.dialog.open(ConfirmDeleteDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: "User"
    });
    ref.afterClosed()
      .pipe(filter(result => result))
      .subscribe(this.deleteUserData.bind(this))
  }

  async deleteUserData() {
    concat(this.deleteUser.execute(this.visibleUser!.id),
      this.logoutUser.execute(),
    ).subscribe(_ => { this.router.navigateByUrl('/login') })
  }

  //TODO Translate error messages
  imageChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const max_size = 100000; // Size Filter Bytes
      const allowed_types = ['image/png', 'image/jpeg'];

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 + 'Kb';
        this.quickAlert('Image Error', this.imageError);
        return;
      }

      //TODO: fix or remove this
      // if (fileInput.target.files[0].type.includes(allowed_types) ) {
      //   this.imageError = 'Only Images are allowed ( JPG | PNG )';
      //   this.quickAlert("Error", this.imageError);
      // }

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          this.visibleUser!.image = { image: e.target.result };
          this.userDataChanged();
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  public quickAlert(header: string,
    message: string,
    button: string = "OK") {
    this.dialog.open(AlertDialogComponent, {
      data: {
        header: header,
        message: message,
        button: button
      }
    })
  }

}
