import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GroupModel } from 'src/app/core/models/group.model';
import { GroupTreeService } from 'src/app/core/services/group-tree.service';
import { Uuid } from 'src/app/core/types/Uuid';
import { GetGroupByIdUsecase, OpenLockUsecase, UpdateGroupUsecase } from 'src/app/core/useCases/group.usecases';
import { UiGroupMapper } from '../../mappers/ui-group.mapper';
import { UiGroupModel } from '../../models/ui-group.model';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})
export class GroupDetailsComponent implements OnInit, OnDestroy {
  // initialize as placeholder
  visibleGroup: UiGroupModel = {
    frozen: false,
    generic: undefined,
  };
  panelOpenState = false;
  groupChanged = false;
  uiGroupMapper = new UiGroupMapper();
  formGroupDetails: FormGroup;
  breadCrumpGroups: GroupModel[] | undefined = [];
  editForbidden = false;
  routeSubscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getGroupUseCase: GetGroupByIdUsecase,
    //private getAllGroupsUseCase: GetAllGroupsUsecase,
    private updateGroupUseCase: UpdateGroupUsecase,
    private formBuilder: FormBuilder,
    private groupTreeService: GroupTreeService,
    private openLockUseCase: OpenLockUsecase,
  ) {
    this.formGroupDetails = this.formBuilder.group({
      name: [
        {value: '', disabled: this.editForbidden },
        [Validators.required, Validators.maxLength(200)]
      ],
      description: [
        {value: '', disabled: this.editForbidden }, 
        Validators.maxLength(200)
      ],
      parentId: [{ value: '', disabled: this.editForbidden }],
      address: this.formBuilder.group({
        street: [
          {value: '', disabled: this.editForbidden }, 
          Validators.maxLength(200)
        ],
        number: [
          {value: '', disabled: this.editForbidden }, 
          Validators.maxLength(200)
        ],
        zip: [
          { value: '', disabled: this.editForbidden }, 
          [Validators.maxLength(5), Validators.pattern('[0-9]{5}')]
        ],
        city: [
          {value: '', disabled: this.editForbidden }, 
          Validators.maxLength(200)
        ],
        country: [
          {value: '', disabled: this.editForbidden },
          Validators.maxLength(200)
        ],
        extra: [
          {value: '', disabled: this.editForbidden },
          Validators.maxLength(200)
        ],
      }),
    });
    this.router.onSameUrlNavigation = 'reload';
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe(p => 
        this.onPageLoaded(p["groupid"])
      );
  }

  ngOnDestroy(){
    this.routeSubscription.unsubscribe();
  }

  onPageLoaded(groupId: Uuid) {
    this.getGroupUseCase
      .execute(groupId)
      .subscribe(this.onGroupLoaded.bind(this))
    this.breadCrumpGroups = this.groupTreeService.currentGroupBreadCrumpList$.getValue();
    //remove selected group from breadcrumps
    this.breadCrumpGroups = this.breadCrumpGroups?.filter(
      bcg => bcg.id != groupId 
    )
  }

  onGroupLoaded(group: GroupModel) {
    this.visibleGroup = this.uiGroupMapper.mapTo(group);
    if (group.permissions!.edit){
      this.formGroupDetails.enable();
      this.editForbidden = false;
    } else {
      this.formGroupDetails.disable();
      this.editForbidden = true;
    }
    this.formGroupDetails.patchValue({
      name: group.name,
      description: group.description,
      parentId: group.parentId,
      address: {
        street: group.address?.street,
        number: group.address?.number,
        city: group.address?.city,
        zip: group.address?.zip,
        country: group.address?.country,
        extra: group.address?.extra,
      }
    });
    if (group.address){
      if (this.hasValues(group.address)){
        this.panelOpenState = true;
      }
    } else {
      this.panelOpenState = false;
    }
    
  }

  getAddressFromForm() {

  };

  //checks if given object contains something other than: 
  //  null, undefined, an empty string
  hasValues = (obj: any) => Object.values(obj).some( val => 
        val !== null && 
        typeof val !== "undefined" && 
        val !== ""
  )

  updateGroup() {
    let reloadGroupTree = false;
    //check if name changed => groupTree changed
    if (this.visibleGroup.name != this.formGroupDetails.value.name) {
      this.visibleGroup.name = this.formGroupDetails.value.name;
      reloadGroupTree = true;
    }
    //check if parent changed => groupTree changed
    if (this.visibleGroup.id != this.formGroupDetails.value.parentId){
      this.visibleGroup.parentId = this.formGroupDetails.value.parentId;
      reloadGroupTree = true;
    }
    
    //check if address was changed
    if (this.formGroupDetails.get("address")?.dirty){
        this.visibleGroup.address = {
          street: this.formGroupDetails.get("address")!.value.street,
          number: this.formGroupDetails.get("address")!.value.number,
          city: this.formGroupDetails.get("address")!.value.city,
          zip: this.formGroupDetails.get("address")!.value.zip,
          country: this.formGroupDetails.get("address")!.value.country,
          extra: this.formGroupDetails.get("address")!.value.extra,
        };
        if (!this.hasValues(this.visibleGroup.address )){
          delete this.visibleGroup['address'];
        }
    }
    //get rest of fields
    this.visibleGroup.description = this.formGroupDetails.value.description;

    this.updateGroupUseCase
      .execute(this.uiGroupMapper.mapFrom(this.visibleGroup))
      .subscribe(_ => {
        if (reloadGroupTree){
          this.groupTreeService.loadGroupTree();
        }
        else
          this.formGroupDetails.markAsPristine();
      })
  }

  openCurrentLock(){
    this.openLockUseCase.execute(this.visibleGroup as GroupModel).subscribe();
  }
}
