// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts`
// The list of file replacements can be found in `angular.json`.
import { AuthConfig } from "angular-oauth2-oidc";

export const environment = {
  name: "staging",
  // set to 'path' for /no_hash_pages
  // set to '' for /#/pages
  locationStrategy: "path",
  production: false,
  showErrorDetails: true,
  apiUrl: 'https://staging.comydo.com/api/v1/',
};

export const authCodeFlowConfig: AuthConfig = {
  tokenEndpoint: environment.apiUrl + "o/token/",
  revocationEndpoint: environment.apiUrl + "o/revoke_token/",
  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: '3KHjMHiJHkKZr7w603v6AbJ4bamwvlxCSvfXRnvC',
  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  dummyClientSecret: '4O0hOKKxklq0nc8cMUcxTmdIhFvc8dsjuMOhFzR1mRgJY92mqOvkHPnO4PR3fVdevkCnOOD7d4NWojMrdTdHodQNBgcGB9MKSziDcrmtCzdKWPxJhvwFvy6rcuxEfoib',
  responseType: "code",
  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: "read write api",
  showDebugInformation: true,
  // Not recommented:
  // disablePKCI: true,
  requireHttps: true,
};
