import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ComydoKeyType } from 'src/app/core/types/KeyType';
import { Uuid } from 'src/app/core/types/Uuid';
import { DeleteKeyUsecase, GetAllUnassignedKeysUsecase } from 'src/app/core/useCases/key.usecases';
import { ConfirmDeleteDialogComponent } from 'src/app/presentation/dialogs/confirm-delete.dialog';
import { UiKeyMapper } from 'src/app/presentation/mappers/ui-key.mapper';
import { UiKeyModel } from 'src/app/presentation/models/ui-key.model';
import { NotificationService } from 'src/app/presentation/services/notification.service';

@Component({
  selector: 'app-key-without-key-right-editor',
  templateUrl: './key-without-key-right-editor.component.html',
  styleUrls: ['./key-without-key-right-editor.component.scss']
})
export class KeyWithoutKeyRightEditorComponent implements OnInit, OnDestroy {
  allUnassingedKeys: UiKeyModel[] = [];
  uiKeyMapper = new UiKeyMapper();

  keyTableColumns = ['name', 'keyTeeth'];
  keyTableColumnsAll = ['select', ...this.keyTableColumns];
  keyTableDataSource: MatTableDataSource<UiKeyModel> = new MatTableDataSource();

  ComydoKeyType = ComydoKeyType;

  routeSubscription!: Subscription;

  @ViewChild(MatSort, { static: true }) sort?: MatSort;


  constructor(
    private getAllUnassignedKeys: GetAllUnassignedKeysUsecase,
    private dialog: MatDialog,
    private deleteKeyUseCase: DeleteKeyUsecase,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
  ) {
    this.routeSubscription = this.route.params.subscribe(p => 
      this.onPageLoaded(p["customerId"])
    );
  }
  
  onPageLoaded(customerId: Uuid): void {
    this.getAllUnassignedKeys.execute(customerId).subscribe(keys => {
      this.allUnassingedKeys = 
        keys.map(this.uiKeyMapper.mapTo.bind(this));
      this.keyTableDataSource.data = this.allUnassingedKeys;
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void{
    this.routeSubscription.unsubscribe();
  }

  confirmKeysDelete(keys: Array<UiKeyModel>) {
    //TODO(mo): list keys to delete 
    const ref = this.dialog.open(ConfirmDeleteDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: "Keys"
    });
    ref.afterClosed()
      .pipe(
        filter(result => result),
      )
      .subscribe(this.onDeleteKeys.bind(this, keys))
  }

  onDeleteKeys(keys: Array<UiKeyModel>) {
    let deleteOps = [];
    for (const key of keys) {
      deleteOps.push(
        this.deleteKeyUseCase.execute(this.uiKeyMapper.mapFrom(key))
      );
    }
    const deleteSelected = forkJoin(deleteOps);
    deleteSelected.subscribe(
      () => {
        for (const key of keys) {
          this.keyTableDataSource.data.splice(this.keyTableDataSource.data.indexOf(key), 1);
          this.keyTableDataSource._updateChangeSubscription();
        }
      },
      err => this.notificationService.notifyError("Key deletion failed: " + err.message)
    )
  };

}
