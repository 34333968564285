<div class="data-table-container">
  <div class="actions-header">
    <button mat-icon-button
      *ngIf="this.addButtonEnabled" 
      class="add-key-button"  
      (click)="addRow.emit()"
    >
      <mat-icon>add_circle_outline</mat-icon>
    </button>
    <button mat-icon-button 
      color="primary" 
      *ngIf="this.editableFields && this.editButtonEnabled" 
      [disabled]="!canEdit()" 
      (click)="editRow.emit(selection.selected[0])"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-icon-button 
      color="warn" 
      *ngIf="this.editableFields && this.deleteButtonEnabled" 
      [disabled]="selection.isEmpty()" 
      (click)="deleteRows.emit(selection.selected)"
    >
      <mat-icon>delete_outline</mat-icon>
    </button>
    <mat-form-field class="filter">
      <mat-label>Filter</mat-label>
      <input matInput [(ngModel)]="dataSource.filter" #input>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="scrollbox">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
      <ng-container matColumnDef="select">
        <th mat-header-cell 
          *matHeaderCellDef 
          [hidden]="!this.editableFields"
        >
          <mat-checkbox 
            (change)="$event ? masterToggle() : null" 
            [checked]="selection.hasValue() && isAllSelected"
            [indeterminate]="selection.hasValue() && !isAllSelected"
          >
          </mat-checkbox>
        </th>
        <td mat-cell 
          *matCellDef="let row" 
          [hidden]="!this.editableFields"
        >
          <mat-checkbox 
            (click)="$event.stopPropagation()" 
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-content></ng-content>
    <!-- Row shown when there is no matching data. -->
      <tr mat-header-row *matHeaderRowDef="allColumns; sticky: true"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="3">
          {{"Nothing to display" | translate }}
        </td>
      </tr>
    </table>
  </div>
</div>
