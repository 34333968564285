import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Backend } from '../base/backend';
import { UseCase } from '../base/use-case';
import { GroupTreeModel } from '../models/group-tree.models';
import { GroupModel } from '../models/group.model';
import { Uuid } from '../types/Uuid';


@Injectable({
  providedIn: 'root'
})
export class GetAllGroupsUsecase implements UseCase<void, Array<GroupModel>> {

  constructor(private userRepository: Backend) { }

  execute(params: void): Observable<Array<GroupModel>> {
    return this.userRepository.getAllGroups();
  }
}

@Injectable({
  providedIn: 'root'
})
export class CreateGroupUsecase implements UseCase<GroupModel, GroupModel> {

  constructor(private userRepository: Backend) { }

  execute(params: GroupModel): Observable<GroupModel> {
    return this.userRepository.createGroup(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class GetGroupByIdUsecase implements UseCase<Uuid, GroupModel> {

  constructor(private userRepository: Backend) { }

  execute(params: Uuid): Observable<GroupModel> {
    return this.userRepository.getGroupById(params);
  }
}


@Injectable({
  providedIn: 'root'
})
export class UpdateGroupUsecase implements UseCase<GroupModel, void> {

  constructor(private userRepository: Backend) { }

  execute(params: GroupModel): Observable<void> {
    return this.userRepository.updateGroup(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeleteGroupUsecase implements UseCase<GroupModel, void> {

  constructor(private userRepository: Backend) { }

  execute(params: GroupModel): Observable<void> {
    return this.userRepository.deleteGroup(params);
  }
}

@Injectable({
  providedIn: 'root'
})
export class GetGroupTreeUsecase implements UseCase<void, Array<GroupTreeModel>> {

  constructor(private userRepository: Backend) { }

  execute(params: void): Observable<Array<GroupTreeModel>> {
    return this.userRepository.getGroupTrees();
  }
}

@Injectable({
  providedIn: 'root'
})
export class GetKeyExpirationStatus implements UseCase<[groupId: Uuid, keyId: Uuid], KeyStateModel> {
  
  constructor(private userRepository: Backend) {}
  
  execute(params: [groupId: Uuid, keyId: Uuid] ): Observable<KeyStateModel> {
    return this.userRepository.getKeyExpirationStatus(params);
  }

  
}

@Injectable({
  providedIn: 'root'
})
export class OpenLockUsecase implements UseCase<GroupModel, void>{

  constructor(private userRepository: Backend) {}

  execute(params: GroupModel): Observable<void> {
    return this.userRepository.openLock(params);
  }
}
