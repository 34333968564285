import { ComydoKeyType } from '../types/KeyType';
import { Uuid } from '../types/Uuid';
import { KeyRightModel } from './key-right.model';
import { PermissionBaseModel } from './permission.models';

export enum ReoccurEnum {
    once = "ONCE",
    day = "DAY",
    week = "WEEK",
    month = "MONTH",
    year = "YEAR"
}

export abstract class KeyModelGenericProperties{
    licensePlate?: string;
    type: ComydoKeyType = ComydoKeyType.Custom;
    // ...
};

export abstract class KeyModel {
    id?: Uuid;
    name!: string;
    keyTeeth!: string;
    keyHolder ?: Uuid;
    generic!: KeyModelGenericProperties;
    keyRights!: Array<KeyRightModel>
    permissions?: PermissionBaseModel;
}
