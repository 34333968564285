import { SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Mapper } from "src/app/core/base/mapper";
import { ImagePngModel } from "src/app/core/models/image-png.model";
import { convertABtoB64s, convertB64toAB } from "src/app/shared/util";
import { UiImagePngModel } from "../models/ui-image-png.model";

export class UiImagePngMapper extends Mapper<UiImagePngModel, ImagePngModel>{
    constructor(private sanitizer: DomSanitizer){
        super();
    }

    mapFrom(param: UiImagePngModel): ImagePngModel {
        let base64image = param.image?.toString()?.replace(
            /^data:image\/[a-z]+;base64,/, "");
        return {
            image: convertB64toAB(base64image)
        }
    }

    mapTo(param: ImagePngModel): UiImagePngModel {
        let base64String = convertABtoB64s(param.image);
        const sanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
            'data:image/png;base64,' + base64String);
        const sanitizer_output = this.sanitizer.sanitize(
            SecurityContext.URL, sanitized);
        if (!sanitizer_output){
            //here be errors
            throw new Error("Mapping image from ImagePngModel to UiImagePngModel failed")
        }
        return {
            image: sanitizer_output
        }
    }
}
