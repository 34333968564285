import { Injectable } from '@angular/core';
import { Backend } from '../base/backend';
import { UseCase } from '../base/use-case'
import { InvitationModel } from '../models/invitation.model';
import { Observable } from 'rxjs';
import { Uuid } from '../types/Uuid';

@Injectable({
  providedIn: 'root'
})

export class CreateInvitationUsecase implements UseCase<InvitationModel, InvitationModel> {
  constructor(
    private userRepository: Backend
  ) { }

  execute(params: InvitationModel): Observable<InvitationModel> {
    return this.userRepository.createUserInvitation(params);
  }
}

@Injectable({
    providedIn: 'root'
  })

export class GetAllInvitationsUsecase implements UseCase<void, Array<InvitationModel>> {
    constructor(
      private userRepository: Backend,
      ) {}
  
    execute(params: void): Observable<Array<InvitationModel>> {
      return this.userRepository.getAllInvitations();
    }
}

@Injectable({
    providedIn: 'root'
  })

export class ResendInvitationUsecase implements UseCase<Uuid, void> {
    constructor(
        private userRepository: Backend,
    ) {}

    execute(params: Uuid): Observable<void> {
        return this.userRepository.resendInvitation(params);
    }
}
