import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Credentials } from 'src/app/core/models/credentials.model';
import { UserModel } from 'src/app/core/models/user.model';
import { LoginUserUsecase, LogoutUserUsecase } from 'src/app/core/useCases/credentials.usecases';
import { GetCurrentUserUsecase, UpdateCurrentUserUsecase } from 'src/app/core/useCases/user.usecases';
import { UiUserMapper } from 'src/app/presentation/mappers/ui-user.mapper';
import { UiUserModel } from 'src/app/presentation/models/ui-user.model';
import { MatchFormField } from 'src/app/shared/util';
import { maxPasswordLength, minPasswordLength } from 'src/global';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  currentUser?: UiUserModel;
  uiUserMapper = new UiUserMapper(this.sanitizer);

  constructor(
    private formBuilder: FormBuilder,
    private getCurrentUser: GetCurrentUserUsecase,
    private updateCurrentUser: UpdateCurrentUserUsecase,
    private logoutUser: LogoutUserUsecase,
    private loginUser: LoginUserUsecase,
    private sanitizer: DomSanitizer,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(minPasswordLength),
        Validators.maxLength(maxPasswordLength)],
      ],
      confirmNewPassword: ['', [
        Validators.required,
        Validators.minLength(minPasswordLength),
        Validators.maxLength(maxPasswordLength)],
      ]
    },
      { validator: MatchFormField('newPassword', 'confirmNewPassword') }
    );

  }

  ngOnInit(): void {
    this.getCurrentUser.execute().subscribe(value => {
      this.currentUser = this.uiUserMapper.mapTo(value);
    }
    );
  }

  async submitForm() {
    let userWithPassword: UserModel;

    if (await this.verifyCurrentPassword(this.changePasswordForm.get('password')?.value)) {
      if (this.currentUser) {
        userWithPassword = this.uiUserMapper.mapFrom(this.currentUser);
        userWithPassword.password = this.changePasswordForm.get('newPassword')?.value;
        this.updateCurrentUser.execute(userWithPassword).subscribe({
          complete: () => {
            this.logoutUser.execute().subscribe({
              complete: () => {
                this.present_snack_msg("Changing PW successful")
                this.router.navigateByUrl('/login')
              }
            });
          }
        }
        );
      }
    }
    else {
      this.present_snack_msg("Changing PW failed")
    }
  }

  async verifyCurrentPassword(pw: string): Promise<boolean> {
    if (this.currentUser?.email) {
      let creds: Credentials = {
        email: this.currentUser.email,
        password: pw
      }
      return await this.loginUser.execute(creds).toPromise();
    }
    return false;
  }

  async present_snack_msg(msg: string) {
    this._snackBar.open(msg, "OK", {
      duration: 5000,
      verticalPosition: 'top',
    });
  }
}
