<div class="layout-container">
  <div class="layout">
    <div class="content">
      <mat-form-field class="filter">
        <mat-label position="floating">Filter</mat-label>
        <input matInput [(ngModel)]="filter" (ngModelChange)="updateFilter()">
      </mat-form-field>
    
      <table mat-table matSort [dataSource]="dataSource">
    
        <ng-container matColumnDef="serialNr">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{"Serial Nr" | translate}}
          </th>
          <td mat-cell *matCellDef="let device">
            {{device.serialNr}}
          </td>
        </ng-container>
    
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{"Name" | translate}}
          </th>
          <td mat-cell *matCellDef="let device">
            {{device.name}}
          </td>
        </ng-container>
    
        <!-- <ng-container matColumnDef="addressString">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{"Address" | translate}}
          </th>
          <td mat-cell *matCellDef="let device" (click)="openDialog(device)">
            {{device.addressString}}
          </td>
        </ng-container> -->
    
        <ng-container matColumnDef="deviceMqttId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{"Public ID" | translate}}
          </th>
          <td mat-cell *matCellDef="let device">
            {{device.deviceMqttId}}
          </td>
        </ng-container>
    
        <ng-container matColumnDef="uptime1w">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{"Uptime Week" | translate}}
          </th>
          <td mat-cell *matCellDef="let device"
            [ngStyle]="{'color': calcUptimeColor(device?.stats?.uptime1w)}">
            <ng-container *ngIf="device?.stats?.uptime1w !== null">
              ⬤ {{device?.stats?.uptime1w * 100 | number:'1.0-1'}}%
            </ng-container>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="uptime24h">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{"Uptime Day" | translate}}
          </th>
          <td mat-cell *matCellDef="let device"
            [ngStyle]="{'color': calcUptimeColor(device?.stats?.uptime24h)}">
            <ng-container *ngIf="device?.stats?.uptime24h !== null">
              ⬤ {{device?.stats?.uptime24h * 100 | number:'1.0-1'}}%
            </ng-container>
          </td>
        </ng-container>
    
        <ng-container matColumnDef="uptime1h">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{"Uptime Hour" | translate}}
          </th>
          <td mat-cell *matCellDef="let device"
            [ngStyle]="{'color': calcUptimeColor(device?.stats?.uptime1h)}">
            <ng-container *ngIf="device?.stats?.uptime1h !== null">
              ⬤ {{device?.stats?.uptime1h * 100 | number:'1.0-1'}}%
            </ng-container>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
      </table>
    </div>
    
    <mat-paginator class="footer" showFirstLastButtons [length]="100" [pageSize]="25"
      [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </div>
</div>
