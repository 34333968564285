import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { KeyShareMailModel } from "src/app/core/models/key-share-model";
import { Uuid } from "src/app/core/types/Uuid";
import { ShareKeyMailUseCase } from "src/app/core/useCases/key.usecases";

@Component({
    selector: 'app-key-mail-dialog',
    templateUrl: 'key-mail.dialog.html',
    styleUrls: ['key-mail.dialog.scss']
})
export class KeyMailDialogComponent {
    newKeyMailForm: FormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Uuid,
        private shareKeyMailUseCase: ShareKeyMailUseCase,
        private formBuilder: FormBuilder,
    ){
        this.newKeyMailForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        })
    }

    shareKeyMail(){
        let keyMail: KeyShareMailModel ={
            keyID: this.data,
            recipients: [this.newKeyMailForm.get("email")?.value],
        };
        this.shareKeyMailUseCase.execute(keyMail).subscribe();
    }

}
