import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GetCurrentUserUsecase } from 'src/app/core/useCases/user.usecases';
import { Backend } from '../base/backend';
import { UserModel } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    
    public currentUser$ = new BehaviorSubject<UserModel | undefined>(undefined);
    
    public get isUserLoggedIn(): boolean {
        return !!this.currentUser$.value;
    }

    constructor(private getCurrentUser: GetCurrentUserUsecase, private backend: Backend) { }

    public onUserLogin() {
        this.loadUser$(true).subscribe(v => this.currentUser$.next(v))
    }

    public onUserUpdated() {
        this.loadUser$(true).subscribe(v => this.currentUser$.next(v))
    }

    public onUserDeleted() {
        this.reset()
    }

    public onUserLogout() {
        this.reset()
    }

    public onAuthStateChange(isAuthenticated: boolean) {
        if (isAuthenticated) {
            this.loadUser$(false).subscribe(v => this.currentUser$.next(v))
            return
        }
        this.reset()
    }

    private reset() {
        this.currentUser$.next(undefined);
    }

    private loadUser$(force: boolean): Observable<UserModel | undefined> {
        return this.backend.isAuthenticated()
            .pipe(
                switchMap(isAuthenticated => {
                    if (!isAuthenticated) {
                        return throwError(new UserAuthError("no valid auth token"))
                    }
                    let doLoad = this.currentUser$.value === undefined || force;
                    return doLoad ?
                        this.getCurrentUser.execute()
                        : of(this.currentUser$.value);
                })
            )
    }
}

export class UserAuthError extends Error {
    cause?: Error
    constructor(message: string, cause?: Error) {
        super(message)
        this.cause = cause
    }
}
