import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login-signup-page',
  templateUrl: './login-signup.page.html',
  styleUrls: ['./login-signup.page.scss']
})
export class LoginSignupPageComponent implements OnInit {
  keyParam!: string;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const key = this.route.snapshot.queryParamMap.get('key')!;
    this.route.queryParams.subscribe(p => this.keyParam = p['key']);
  }

}
