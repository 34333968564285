import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LogoutUserUsecase } from 'src/app/core/useCases/credentials.usecases';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.page.html',
})
export class LogoutPageComponent {
  constructor(
    private router: Router,
    private logoutUser: LogoutUserUsecase,
  ) {
    this.logout();
  }

  logout() {
    this.logoutUser
      .execute()
      .subscribe(
        succ => {
          return succ
            && this.router.navigateByUrl('/login');
        }
      );
  }
}
