<form [formGroup]="form" class="mat-typography">
  <h2 translate>createProfile</h2>
  <div class="row">
    <mat-form-field appearance="outline">
      <mat-label for="firstName">
        {{ 'First Name' | translate }}
      </mat-label>
      <input matInput id="firstName" type="firstName" class="validate" formControlName="firstName">
      <mat-error *ngIf="getFormControls.firstName?.errors?.required" translate>required</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label for="lastName">
        {{ 'Last Name' | translate }}
      </mat-label>
      <input matInput id="lastName" type="lastName" class="validate" formControlName="lastName">
      <mat-error *ngIf="getFormControls.lastName?.errors?.required" translate>required</mat-error>
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline">
    <mat-label for="profession">
      {{ 'Profession' | translate }}
    </mat-label>
    <input matInput id="profession" type="profession" class="validate" formControlName="profession">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label for="comment">
      {{ 'Comment' | translate }}
    </mat-label>
    <input matInput id="comment" type="comment" class="validate" formControlName="comment">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label for="company">
      {{ 'Company' | translate }}
    </mat-label>
    <input matInput id="company" type="company" class="validate" formControlName="company">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label for="password">
      {{ 'Password' | translate }}
    </mat-label>
    <input matInput id="pwd" type="password" class="form-control" formControlName="password">
    <mat-error *ngIf="getFormControls.password?.errors?.required" translate>required</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label for="passwordRepeat">
      {{ 'Confirm Password' | translate }}
    </mat-label>
    <input matInput id="pwd-repeat" type="password" class="form-control" formControlName="passwordRepeat">
    <mat-error *ngIf="getFormControls.passwordRepeat?.errors?.required" translate>required</mat-error>
    <mat-error *ngIf="getFormControls.passwordRepeat?.errors?.confirmedValidator" translate>required</mat-error>
  </mat-form-field>

  <button mat-button color="primary" class="go-to-login-button" (click)="goToLogin()" type="button">
    <span>{{ 'Already have an account?' | translate }}</span>
  </button>

  <div class="filler"></div>
  <div class="row right">


    <button (click)="submitForm()" mat-flat-button color="accent" type="submit" [disabled]="form.invalid">
      {{ 'Register' | translate }}
    </button>
  </div>

</form>