import { Mapper } from 'src/app/core/base/mapper';
import { WebInvitationModel } from '../models/web-invitation.model';
import { InvitationModel } from 'src/app/core/models/invitation.model';


export class WebInvitationMapper extends Mapper<WebInvitationModel, InvitationModel>{
    mapFrom(param: WebInvitationModel): InvitationModel {
        return param as InvitationModel
    }

    mapTo(param: InvitationModel): WebInvitationModel {
        return param as WebInvitationModel
    }
}
